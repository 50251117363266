const initialState = {
  faqs: [],
  categories: [],
  all_categories: [],
  template_setting:[],
  all_template: [],
  all_product: [],
  faq_product: [],
  faq_messages_setting: [],
  faq_messages: [],
  faq_more_page: [],
  faq_more_page_setting: [],
  user: [],
  all_faqs: [],
  admin_account: {
    user_name: 'faqadmin',
    password: 'faq@yanet2022',
  },
  showBannerReturn: true,
  product_data: [],
  adminIsAuthenticated : true,
  rating_data: {},
  faq_more_page_setting_create:{
    home_page_visible: false,
    cms_page_visible : false,
    cart_page_visible : false, 
    collection_page_visible : false,
    product_page_visible : false,
    active_feature: false,
    active_template: true,
  },
  settings:{}
}
export const data = {
  namespaced: true,
  state: initialState,
  actions: {
    setFaqsData({ commit }, faqs) {
      commit('setFaqsData', faqs)
    },
    
    setAllFaqsData({ commit }, all_faqs) {
      commit('setAllFaqsData', all_faqs)
    },

    setCategoriesData({ commit }, categories) {
      commit('setCategoriesData', categories);
    },

    setAllCategoriesData({ commit }, all_categories) {
      commit('setAllCategoriesData', all_categories);
    },

    setSettingsData({commit}, settings) {
      commit('setSettingsData',settings)
    },

    setTemplateData({commit}, template_setting) {
      commit('setTemplateData', template_setting)
    },

    setAllTemplateData({commit}, all_template) {
      commit('setAllTemplateData', all_template)
    },

    setProductsData({commit}, all_product) {
      commit('setProductsData', all_product)
    },

    setProductShopify({commit}, product_data) {
      commit('setProductShopify', product_data)
    },
    
    setFaqProductData({commit}, faq_product) {
      commit('setFaqProductData', faq_product)
    },

    setFaqMessagesData({commit}, faq_messages) {
      commit('setFaqMessagesData', faq_messages)
    },

    setFaqMessagesSetting({commit}, faq_messages_setting) {
      commit('setFaqMessagesSetting', faq_messages_setting)
    },

    setFaqMorePage({commit}, faq_more_page) {
      commit('setFaqMorePage', faq_more_page)
    },

    setFaqMorePageSetting({commit}, faq_more_page_setting) {
      commit('setFaqMorePageSetting', faq_more_page_setting)
    },

    setUserData({commit}, user) {
      commit('setUserData', user)
    },

    setRatingData({commit}, rating_data) {
      commit('setRatingData', rating_data)
    },
    setAdminAuth({commit}, adminIsAuthenticated) {
      commit('setAdminAuth', adminIsAuthenticated)
    },
    setShowBannerReturn({commit}, showBannerReturn) {
      commit('setShowBannerReturn', showBannerReturn)
    },
  },
  mutations: {
    setFaqsData(state, faqs) {
      state.faqs = faqs;
    },
    setAllFaqsData(state, all_faqs) {
      state.all_faqs = all_faqs;
    },
    setCategoriesData(state, categories) {
      state.categories = categories;
    },
    setAllCategoriesData(state, all_categories) {
      state.all_categories = all_categories;
    },
    setSettingsData(state, settings) {
      state.settings = settings;
    },
    setTemplateData(state, template_setting) {
      state.template_setting = template_setting
    },
    setAllTemplateData(state, all_template) {
      state.all_template = all_template
    },
    setProductsData(state, all_product) {
      state.all_product = all_product
    },
    setProductShopify(state, product_data) {
      state.product_data = product_data
    },
    setFaqProductData(state, faq_product) {
      state.faq_product = faq_product
    },
    setFaqMessagesData(state, faq_messages) {
      state.faq_messages = faq_messages
    },
    setFaqMessagesSetting(state, faq_messages_setting) {
      state.faq_messages_setting = faq_messages_setting
    },
    setFaqMorePage(state, faq_more_page) {
      state.faq_more_page = faq_more_page
    },
    setFaqMorePageSetting(state, faq_more_page_setting) {
      state.faq_more_page_setting = faq_more_page_setting
    },
    setUserData(state, user) {
      state.user = user
    },
    setRatingData(state, rating_data) {
      state.rating_data = rating_data
    },
    setAdminAuth(state, adminIsAuthenticated) {
      state.adminIsAuthenticated = adminIsAuthenticated
    },
    setShowBannerReturn(state, showBannerReturn) {
      state.showBannerReturn = showBannerReturn;
    },
  }
};