import MerchantLogin from './../../components/merchant/MerchantLogin.vue'

const merchantRoutes = [
  {
    path: "/merchant/login",
    name: "merchant-login",
    component: MerchantLogin,
    meta: { merchant: true },
  },
]

export default merchantRoutes