<template>
    <div class="modal fade" id="subscription" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="subscription" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen modal-dialog-centered">
      <div class="modal-content">
        <button type="button" style="position: absolute; top: 20px; right: 20px; z-index: 100; cursor: pointer; font-size: 20px;" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <div class="modal-body d-flex flex-column">
          <Plan style="position: relative !important; top: 0">
          </Plan>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Plan from './Plan.vue';
  export default {
    components:{
      Plan: Plan
    }
  }
</script>