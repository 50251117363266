export const templates = {
  template_1 : {
    template_number: 1,
    setting_id: '',
    width_faqs_accordian: 1180,
    width_faqs_product: 1180,
    faq_font_color: "#000000",
    faq_font_size: 20,
    faq_font_weight: "bold",
    faq_font_family: "unset",
    faq_hover_color: "",
    category_font_color: "#000000",
    category_font_size: 24,
    category_font_weight: "bold",
    category_font_family: "unset",
    category_text_style: "unset",
    category_text_align: "center",
    answer_font_size: 16,
    answer_font_weight: "normal",
    answer_font_color: "#000000",
    answer_bg_color: "",
    answer_font_family: "unset",
    custom_css: '',
    banner_height: 300,
    banner_visible: true,
    banner_default:'banner-default-1.png',
    intro_text_paddingtop: 10,
    intro_text_fontsize: 16,
    intro_text_paddingbottom: 10,
    intro_text_color: '#ffffff',
    page_title_paddingtop: 14,
    page_title_fontsize: 36,
    page_title_paddingbottom: 14,
    page_title_color: '#ffffff',
    search_input_style: 1,
    footer_text_paddingtop: 10,
    footer_text_fontsize: 16,
    footer_text_paddingbottom: 10,
    footer_text_color: '#000000',
    show_search_input: true,
    show_category_bar: true,
    intro_text_font: 'unset',
    footer_text_font: 'unset',
    search_placeholder_font: 'unset',
    page_title_font: 'unset',
    page_background_color: '#ffffff',
    micro_scope_color: '#000000',
    placeholder_color: '#000000',
    category_bar_number: 1,
    category_bar_background: "#f6f6f7",
    category_bar_color: "#000000",
    btn_top_background: "#000000",
    btn_top_hover: "#383838",
    btn_top_visible: false,
    faq_bg_color: '',
    image_banner: ''
  },
  template_2: {
    template_number: 2,
    setting_id: '',
    width_faqs_accordian: 1180,
    width_faqs_product: 1180,
    faq_font_color: "#ffffff",
    faq_font_size: 16,
    faq_bg_color: "#0368a2",
    faq_font_weight: "normal",
    faq_font_family: "unset",
    faq_hover_color: "#02517d",
    category_font_color: "#000000",
    category_font_size: 16,
    category_font_weight: "bold",
    category_font_family: "unset",
    category_text_style: "unset",
    category_text_align: "left",
    answer_font_size: 16,
    answer_font_weight: "normal",
    answer_font_color: "#000000",
    answer_bg_color: "#eeeeee",
    answer_font_family: "unset",
    custom_css: '',
    banner_height: 300,
    banner_visible: true,
    banner_default:'banner-default-2.png',
    intro_text_paddingtop: 10,
    intro_text_fontsize: 16,
    intro_text_paddingbottom: 10,
    intro_text_color: '#ffffff',
    page_title_paddingtop: 14,
    page_title_fontsize: 36,
    page_title_paddingbottom: 14,
    page_title_color: '#ffffff',
    search_input_style: 1,
    footer_text_paddingtop: 10,
    footer_text_fontsize: 16,
    footer_text_paddingbottom: 10,
    footer_text_color: '#000000',
    show_search_input: true,
    show_category_bar: true,
    intro_text_font: 'unset',
    footer_text_font: 'unset',
    search_placeholder_font: 'unset',
    page_title_font: 'unset',
    page_background_color: '#ffffff',
    micro_scope_color: '#000000',
    placeholder_color: '#000000',
    category_bar_number: 1,
    category_bar_background: "#f6f6f7",
    category_bar_color: "#000000",
    btn_top_background: "#000000",
    btn_top_hover: "#383838",
    btn_top_visible: false,
    image_banner: '',
  },

  template_3: {
    template_number: 3,
    setting_id: '',
    width_faqs_accordian: 1280,
    width_faqs_product: 1180,
    faq_font_color: "#696969",
    faq_font_size: 20,
    faq_font_weight: "normal",
    faq_font_family: "unset",
    category_font_color: "#696969",
    category_font_size: 24,
    category_font_weight: "bold",
    category_font_family: "unset",
    category_text_style: "uppercase",
    category_text_align: "center",
    answer_font_size: 16,
    answer_font_weight: "normal",
    answer_font_color: "#696969",
    answer_font_family: "unset",
    custom_css: '',
    banner_height: 300,
    banner_visible: true,
    banner_default:'banner-default-3.png',
    intro_text_paddingtop: 10,
    intro_text_fontsize: 16,
    intro_text_paddingbottom: 10,
    intro_text_color: '#ffffff',
    page_title_paddingtop: 14,
    page_title_fontsize: 36,
    page_title_paddingbottom: 14,
    page_title_color: '#ffffff',
    search_input_style: 1,
    footer_text_paddingtop: 10,
    footer_text_fontsize: 16,
    footer_text_paddingbottom: 10,
    footer_text_color: '#000000',
    show_search_input: true,
    page_title_font:"unset",
    intro_text_font:"unset",
    search_placeholder_font:"unset",
    footer_text_font:"unset",
    show_category_bar: true,
    page_background_color: '#ffffff',
    micro_scope_color: '#696969',
    placeholder_color: '#696969',
    category_bar_number: 1,
    category_bar_background: "#cccccc",
    category_bar_color: "#000000",
    btn_top_background: "#000000",
    btn_top_hover: "#383838",
    btn_top_visible: false,
    faq_hover_color: "",
    answer_bg_color: "",
    faq_bg_color: '',
    image_banner: ''
  },

  template_4: {
    template_number: 4,
    setting_id: '',
    width_faqs_accordian: 1080,
    width_faqs_product: 1180,
    faq_font_color: "#195f71",
    faq_font_size: 20,
    faq_font_weight: "normal",
    faq_font_family: "unset",
    category_font_color: "#195f71",
    category_font_size: 24,
    category_font_weight: "bold",
    category_font_family: "unset",
    category_text_style: "uppercase",
    category_text_align: "center",
    answer_font_size: 16,
    answer_font_weight: "normal",
    answer_font_color: "#195f71",
    answer_font_family: "unset",
    custom_css: '',
    banner_height: 300,
    banner_visible: false,
    banner_default:'banner-default-3.png',
    intro_text_paddingtop: 10,
    intro_text_fontsize: 16,
    intro_text_paddingbottom: 10,
    intro_text_color: '#195f71',
    page_title_paddingtop: 40,
    page_title_fontsize: 36,
    page_title_paddingbottom: 40,
    page_title_color: '#195f71',
    search_input_style: 2,
    footer_text_paddingtop: 10,
    footer_text_fontsize: 16,
    footer_text_paddingbottom: 10,
    footer_text_color: '#195f71',
    show_search_input: true,
    page_title_font:"unset",
    intro_text_font:"unset",
    search_placeholder_font:"unset",
    footer_text_font:"unset",
    show_category_bar: true,
    page_background_color: '#ffffff',
    micro_scope_color: '#195f71',
    placeholder_color: '#195f71',
    category_bar_number: 2,
    category_bar_background: "#195f71",
    category_bar_color: "#195f71",
    btn_top_background: "#000000",
    btn_top_hover: "#383838",
    btn_top_visible: false,
    faq_hover_color: "",
    answer_bg_color: "",
    faq_bg_color: '',
    image_banner: ''
  },

  template_5: {
    template_number: 5,
    setting_id: '',
    width_faqs_accordian: 1080,
    width_faqs_product: 1180,
    faq_font_color: "#000000",
    faq_font_size: 20,
    faq_font_weight: "normal",
    faq_font_family: "unset",
    faq_bg_color: "#ffffff",
    category_font_color: "#000000",
    category_font_size: 24,
    category_font_weight: "bold",
    category_font_family: "unset",
    category_text_style: "uppercase",
    category_text_align: "center",
    answer_font_size: 16,
    answer_font_weight: "normal",
    answer_font_color: "#000000",
    answer_font_family: "unset",
    answer_bg_color: "#ffffff",
    custom_css: '',
    banner_height: 260,
    banner_visible: false,
    banner_default:'banner-default-3.png',
    intro_text_paddingtop: 10,
    intro_text_fontsize: 16,
    intro_text_paddingbottom: 10,
    intro_text_color: '#000000',
    page_title_paddingtop: 20,
    page_title_fontsize: 36,
    page_title_paddingbottom: 20,
    page_title_color: '#000000',
    search_input_style: 2,
    footer_text_paddingtop: 10,
    footer_text_fontsize: 16,
    footer_text_paddingbottom: 10,
    footer_text_color: '#000000',
    show_search_input: true,
    page_title_font:"unset",
    intro_text_font:"unset",
    search_placeholder_font:"unset",
    footer_text_font:"unset",
    show_category_bar: true,
    page_background_color: '#e9f3f6',
    micro_scope_color: '#000000',
    placeholder_color: '#000000',
    category_bar_number: 2,
    category_bar_background: "#ffffff",
    category_bar_color: "#000000",
    btn_top_background: "#000000",
    btn_top_hover: "#383838",
    btn_top_visible: false,
    faq_hover_color: "",
    image_banner: ''
  },

  template_6:{
    template_number: 6,
    setting_id: '',
    width_faqs_accordian: 1080,
    width_faqs_product: 1180,
    faq_font_color: "#000000",
    faq_font_size: 20,
    faq_font_weight: "normal",
    faq_font_family: "unset",
    faq_hover_color: "#a1a1a1",
    category_font_color: "#000000",
    category_font_size: 24,
    category_font_weight: "bold",
    category_font_family: "unset",
    category_text_style: "uppercase",
    category_text_align: "center",
    answer_font_size: 16,
    answer_font_weight: "normal",
    answer_font_color: "#000000",
    answer_font_family: "unset",
    custom_css: '',
    banner_height: 300,
    banner_visible: false,
    banner_default:'banner-default-3.png',
    intro_text_paddingtop: 10,
    intro_text_fontsize: 16,
    intro_text_paddingbottom: 10,
    intro_text_color: '#ffffff',
    page_title_paddingtop: 20,
    page_title_fontsize: 36,
    page_title_paddingbottom: 20,
    page_title_color: '#ffffff',
    search_input_style: 2,
    footer_text_paddingtop: 10,
    footer_text_fontsize: 16,
    footer_text_paddingbottom: 10,
    footer_text_color: '#000000',
    show_search_input: true,
    page_title_font:"unset",
    intro_text_font:"unset",
    search_placeholder_font:"unset",
    footer_text_font:"unset",
    show_category_bar: true,
    page_background_color: '#ffffff',
    micro_scope_color: '#000000',
    placeholder_color: '#000000',
    category_bar_number: 2,
    category_bar_background: "#ffffff",
    category_bar_color: "#000000",
    btn_top_background: "#000000",
    btn_top_hover: "#383838",
    btn_top_visible: false,
    answer_bg_color: "",
    faq_bg_color: '',
    image_banner: ''
  },

  template_7: {
    template_number: 7,
    setting_id: '',
    width_faqs_accordian: 1080,
    width_faqs_product: 1180,
    faq_font_color: "#000000",
    faq_font_size: 18,
    faq_font_weight: "bold",
    faq_font_family: "unset",
    category_font_color: "#000000",
    category_font_size: 21,
    category_font_weight: "bold",
    category_font_family: "unset",
    category_text_style: "uppercase",
    category_text_align: "center",
    answer_font_size: 16,
    answer_font_weight: "normal",
    answer_font_color: "#000000",
    answer_font_family: "unset",
    custom_css: '',
    banner_height: 250,
    banner_visible: false,
    banner_default:'banner-default-3.png',
    intro_text_paddingtop: 10,
    intro_text_fontsize: 16,
    intro_text_paddingbottom: 10,
    intro_text_color: '#000000',
    page_title_paddingtop: 20,
    page_title_fontsize: 40,
    page_title_paddingbottom: 20,
    page_title_color: '#000000',
    search_input_style: 2,
    footer_text_paddingtop: 10,
    footer_text_fontsize: 16,
    footer_text_paddingbottom: 10,
    footer_text_color: '#000000',
    show_search_input: true,
    page_title_font:"unset",
    intro_text_font:"unset",
    search_placeholder_font:"unset",
    footer_text_font:"unset",
    show_category_bar: false,
    page_background_color: '#ffffff',
    micro_scope_color: '#000000',
    placeholder_color: '#000000',
    category_bar_number: 2,
    category_bar_background: "#ffffff",
    category_bar_color: "#000000",
    btn_top_background: "#000000",
    btn_top_hover: "#383838",
    btn_top_visible: false,
    faq_hover_color: "",
    answer_bg_color: "",
    faq_bg_color: '',
    image_banner: ''
  },

  template_8: {
    template_number: 8,
    setting_id: '',
    width_faqs_accordian: 1080,
    width_faqs_product: 1180,
    faq_font_color: "#000000",
    faq_font_size: 18,
    faq_font_weight: "bold",
    faq_font_family: "unset",
    category_font_color: "#000000",
    category_font_size: 24,
    category_font_weight: "bold",
    category_font_family: "unset",
    category_text_style: "capitalize",
    category_text_align: "center",
    answer_font_size: 16,
    answer_font_weight: "normal",
    answer_font_color: "#000000",
    answer_font_family: "unset",
    custom_css: '',
    banner_height: 210,
    banner_visible: false,
    banner_default:'banner-default-3.png',
    intro_text_paddingtop: 10,
    intro_text_fontsize: 16,
    intro_text_paddingbottom: 10,
    intro_text_color: '#000000',
    page_title_paddingtop: 40,
    page_title_fontsize: 44,
    page_title_paddingbottom: 40,
    page_title_color: '#000000',
    search_input_style: 2,
    footer_text_paddingtop: 10,
    footer_text_fontsize: 16,
    footer_text_paddingbottom: 10,
    footer_text_color: '#000000',
    show_search_input: true,
    page_title_font:"unset",
    intro_text_font:"unset",
    search_placeholder_font:"unset",
    footer_text_font:"unset",
    show_category_bar: true,
    page_background_color: '#ffffff',
    micro_scope_color: '#000000',
    placeholder_color: '#000000',
    category_bar_number: 2,
    category_bar_background: "#ff8200",
    category_bar_color: "#000000",
    btn_top_background: "#ff8200",
    btn_top_hover: "#fea648",
    btn_top_visible: false,
    faq_hover_color: "",
    answer_bg_color: "",
    faq_bg_color: '',
    image_banner: ''
  },

  template_4931: {
    template_number: 4931,
    setting_id: '',
    width_faqs_accordian: 1080,
    width_faqs_product: 1180,
    faq_font_color: "#000000",
    faq_font_size: 20,
    faq_font_weight: "normal",
    faq_font_family: "unset",
    category_font_color: "#000000",
    category_font_size: 24,
    category_font_weight: "bold",
    category_font_family: "unset",
    category_text_style: "uppercase",
    category_text_align: "center",
    answer_font_size: 16,
    answer_font_weight: "normal",
    answer_font_color: "#000000",
    answer_font_family: "unset",
    custom_css: '',
    banner_height: 300,
    banner_visible: false,
    banner_default:'banner-default-3.png',
    intro_text_paddingtop: 10,
    intro_text_fontsize: 16,
    intro_text_paddingbottom: 10,
    intro_text_color: '#000000',
    page_title_paddingtop: 40,
    page_title_fontsize: 36,
    page_title_paddingbottom: 40,
    page_title_color: '#000000',
    search_input_style: 2,
    footer_text_paddingtop: 10,
    footer_text_fontsize: 16,
    footer_text_paddingbottom: 10,
    footer_text_color: '#000000',
    show_search_input: true,
    page_title_font:"unset",
    intro_text_font:"unset",
    search_placeholder_font:"unset",
    footer_text_font:"unset",
    show_category_bar: true,
    page_background_color: '#ffffff',
    micro_scope_color: '#000000',
    placeholder_color: '#000000',
    category_bar_number: 2,
    category_bar_background: "#000000",
    category_bar_color: "#000000",
    btn_top_background: "#000000",
    btn_top_hover: "#383838",
    btn_top_visible: false,
    faq_hover_color: "",
    answer_bg_color: "",
    faq_bg_color: '',
    image_banner: ''
  },

  template_4932: {
    template_number: 4932,
    setting_id: '',
    width_faqs_accordian: 1080,
    width_faqs_product: 1180,
    faq_font_color: "#000000",
    faq_font_size: 20,
    faq_font_weight: "normal",
    faq_font_family: "unset",
    category_font_color: "#000000",
    category_font_size: 24,
    category_font_weight: "bold",
    category_font_family: "unset",
    category_text_style: "uppercase",
    category_text_align: "center",
    answer_font_size: 16,
    answer_font_weight: "normal",
    answer_font_color: "#000000",
    answer_font_family: "unset",
    custom_css: '',
    banner_height: 300,
    banner_visible: false,
    banner_default:'banner-default-3.png',
    intro_text_paddingtop: 10,
    intro_text_fontsize: 16,
    intro_text_paddingbottom: 10,
    intro_text_color: '#000000',
    page_title_paddingtop: 40,
    page_title_fontsize: 36,
    page_title_paddingbottom: 40,
    page_title_color: '#000000',
    search_input_style: 2,
    footer_text_paddingtop: 10,
    footer_text_fontsize: 16,
    footer_text_paddingbottom: 10,
    footer_text_color: '#000000',
    show_search_input: true,
    page_title_font:"unset",
    intro_text_font:"unset",
    search_placeholder_font:"unset",
    footer_text_font:"unset",
    show_category_bar: true,
    page_background_color: '#ffffff',
    micro_scope_color: '#000000',
    placeholder_color: '#000000',
    category_bar_number: 2,
    category_bar_background: "#000000",
    category_bar_color: "#000000",
    btn_top_background: "#000000",
    btn_top_hover: "#383838",
    btn_top_visible: false,
    faq_hover_color: "",
    answer_bg_color: "",
    faq_bg_color: '',
    image_banner: ''
  },

  setting: {
    set_width_product_faq: false,
    tutorial_active: true,
    title_product_faq: false,
    yanet_logo_visible: true,
    category_sort_name: false,
    // status : true,
    faq_sort_name: false,
    faq_uncategory_hidden: true,
    dont_category_faq: false,
    // show_more_faq: false,
    // number_faq_show: 0,
    show_page_title: true,
    show_intro_text: true,
    faq_page_schema: false,
    more_page_schema: false,
    faq_messages_visible: false,
    show_footer_text : true,
    faq_page_url:'/apps/faqs',
    show_page_construction: false,
    page_title_content: `[{"content":"Frequently Asked Questions","locale":"default"}]`,
    intro_text_content: `[{"content":"Check most frequently asked questions here","locale":"default"}]`,
    footer_text_content: `[{"content":"Thanks for visiting our page!","locale":"default"}]`,
    search_placehoder: `[{"content":"What can we help you with?","locale":"default"}]`,
    page_under_contruction: `[{"content":"Page is under construction","locale":"default"}]`,
    search_not_found: `[{"content":"Oops, your search did not match any FAQs","locale":"default"}]`,
    faq_template_number: 2,
    meta_tag_description: ''
  },

}
