<template>
  <div class="px-4 py-3" style="position: relative; top: 41px">
    <div class="fs-5 mb-4 fw-bolder text-nowrap">Analytics</div>
    <div class="product-scroll" style="overflow-x: auto; padding-top: 1px">
      <div class="d-block bg-white border padding text-center" style="border-radius: 10px; min-width: 1100px; overflow: hidden;">
        <div v-if="!loading">
          <div class="p-3 border-bottom d-flex align-items-center fw-bold text-black">
            <div class="d-flex align-items-center col" style="text-align: start">
              <div class="d-flex align-items-center" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#add-category">
                <span class="">FAQs</span>
              </div>
            </div>
            <div class="col-5 d-flex align-items-center">
              <!-- <span class="col text-start" style="text-align: start"></span> -->
              <span class="col">Likes</span>
              <span class="col">Dislikes</span>
              <span class="col">Clicks</span>
            </div>
          </div>
          <div class="px-3 d-flex flex-column align-items-center text-black">
            <div v-for="(item, index) in allFaqs" :key="item" class="w-100 d-flex py-2" :class="(index + 1) === allFaqs.length? '' : 'border-bottom'">
              <div class="d-flex align-items-center col" style="text-align: start">
                <div class="d-flex align-items-center" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#add-category">
                  <span class="">{{ item.title }}</span>
                </div>
              </div>
              <div class="col-5 d-flex align-items-center">
                <!-- <span class="col text-start" style="text-align: start"></span> -->
                <span class="col">{{ item.liked_faq }}</span>
                <span class="col">{{ item.disliked_faq }}</span>
                <span class="col">{{ item.readed_faq }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="">
          <div class="loading">
            <div class="content">
              <h4></h4>
              <div class="description"></div>
            </div>
            <div class="content">
              <h4></h4>
              <div class="description"></div>
            </div>
            <div class="content">
              <h4></h4>
              <div class="description"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { ref } from '@vue/reactivity'
  import FaqDataService from './../../services/FaqDataService'
  export default {
    name: "add-faq",
    components: {
    },
    setup() {
      const loading = ref(true)
      const allFaqs = ref([])
      const retriveAllFaq = async () => {
        await FaqDataService.getAllFaq()
        .then(res => {
          allFaqs.value = res.data
          loading.value = false
        })
      }
      return {
        allFaqs,
        retriveAllFaq,
        loading
      }
    },
    async created() {
      await this.retriveAllFaq()
    },
  }
</script>
<style>
</style>
