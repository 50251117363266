import { createWebHistory, createRouter } from "vue-router";
import AppFAQs from './../components/AppFAQs.vue'
import NotFound from './../components/NotFound.vue'
import Login from './../components/auth/Login.vue'
import Messages from './../components/messages/Messages.vue'
import Translation from './../components/messages/Translation.vue'
import Rating from './../components/rating&suggest-feature/Rating.vue'
import Plan from './../components/plan/Plan.vue'
import SuggestFeature from './../components/rating&suggest-feature/SuggestFeature.vue'
import { isShopifyEmbedded } from '@shopify/app-bridge-utils';
import adminRoutes from './modules/admin'
import merchantRoutes from './modules/merchant'
import categoryRoutes from './modules/category'
import faqRoutes from './modules/faq'
import designSettingRoutes from './modules/design-setting'
import morePageRoutes from './modules/more-page'
import Tutorial from './../components/faqs/Tutorial.vue'
import Document from './../components/document/Document.vue'
// import AddBlockMorePage from './../components/document/AddBlockMorePage.vue'
import store from '../store/index';

const routes = [
  ...adminRoutes,
  ...merchantRoutes,
  {
    path: "/",
    name: "login",
    component: Login,
    meta: { login: true },
  },
  {
    path: "/app",
    name: "app",
    component: AppFAQs,
    children: [
      ...categoryRoutes,
      ...faqRoutes,
      ...designSettingRoutes,
      ...morePageRoutes,
      {
        path: "/suggest-feature",
        name: "suggest-feature",
        component: SuggestFeature,
        meta: { auth: true }
      },
      {
        path: "/rating",
        name: "rating",
        component: Rating,
        meta: { auth: true }
      },
      {
        path: "/plan",
        name: "plan",
        component: Plan,
        meta: { auth: true }
      },
      {
        path: "/message",
        name: "messages",
        component: Messages,
        meta: { auth: true }
      },
      {
        path: "/translation",
        name: "translation",
        component: Translation,
        meta: { auth: true }
      },
      {
        path: '/document',
        name: "document",
        component: Document,
        meta: { auth: true }
      },
      // {
      //   path: '/add-block-more-page',
      //   name: "add-block-more-page",
      //   component: AddBlockMorePage,
      //   meta: { auth: true }
      // },    
    ]
  },
  {
    path: '/tutorial',
    name: "tutorial",
    component: Tutorial,
    meta: { auth: true }
  },
  {
    path: '/not-found',
    name: "NotFound",
    component: NotFound,
  },
  { path: '/:pathMatch(.*)*',
    component: NotFound 
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

let isAdminAuth = false
let isAuthenticated = store.state.auth.isAuthenticated
router.beforeEach((to) => {
  try {
    if (!isShopifyEmbedded()) {
      if(location.href.indexOf('?accessToken') !== -1){
        store.dispatch('auth/setAuth', true);
        isAuthenticated = store.state.auth.isAuthenticated
      }
      if(localStorage.getItem('isAdminAuth')){
        isAdminAuth = localStorage.getItem('isAdminAuth');
      }
      else{
        isAdminAuth = false
      }
      if(to.meta.login && !isAuthenticated) {
        return { name: 'merchant-login' }
      }
      if(to.meta.auth && !isAuthenticated) {
        return { name: 'merchant-login' }
      }   
    }
  }
  catch(err){
    console.log(err)
  }
  if (to.meta.admin && !isAdminAuth) {
    return { name: 'admin-login' }
  }
})

export default router;