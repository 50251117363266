<template>
  <div class="mx-4 my-2">
    <div class="p-3 bgr-primary text-white rounded">
      <div class="d-flex align-items-center">
        <i class="fa-solid fa-circle-exclamation fs-5"></i>
        <div class="ms-2">
          <span>You have reached the faq limit. Please upgrade your plan as soon as possible. </span>
          <span class="fw-bold" style="text-decoration: underline; cursor: pointer" data-bs-toggle="modal" data-bs-target="#subscription"> Upgrade now !</span>
        </div>
      </div>
      <!-- <div @click="notifyVisible = false" class="border rounded" :class="changeButtonColor? 'bg-white text-color-primary' : 'bgr-primary text-white'" @mouseover="changeButtonColor = true" @mouseleave="changeButtonColor = false" style="cursor: pointer; padding: 3px 9px;">
        <i class="fa-solid fa-xmark"></i>
      </div> -->
    </div>
  </div>
</template>
<script>
  export default {
    setup(){
      return {
      }
    },
  }
</script>
<style>
</style>