<template>
  <div class="mb-3" id="header-config">
    <div class="d-flex flex-column flex-lg-row">
      <div class="col-12 col-lg-4">
        <div class="me-0 me-lg-3 product-scroll" style="line-height: 1.8; height: calc(100vh - 108px); overflow-y: auto;position: sticky; top: 130px;">
          <ConfigTemplate
            :setting="settings" 
            :settingOrigin="settingsOrigin"
            :fontFamilyprop="fontFamily"
            :fontWeightsprop="fontWeights"
          />
        </div>
      </div>
      <div class="col-12 col-lg-8 mt-3 mt-lg-0">
        <div class="bg-white border" style="border-radius: 10px; line-height: 1.8 !important">
          <div class="border-bottom fw-bold d-flex flex-column flex-md-row" style="padding: 16px 24px">
            <span class="col fs-6">Live preview</span>
            <div class="col-md-8 col-12 text-start d-flex">
              <a target="_blank" :href="`https://${shopDomain}${settings.faq_page_url}`" class="d-flex align-items-center m-auto text-color-primary" style="text-decoration: none;">
                <span style="word-break:break-word">https://{{ shopDomain }}{{settings.faq_page_url}}
                  <i class="fa-solid fa-arrow-up-right-from-square ms-1"></i>
                </span>
              </a>
            </div>
            <span class="col d-none d-md-block"></span>
          </div>
          <div>
            <img style="width: 100%;" src="./../../../../public/Template4931.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from 'vue'
  import store from '../../../store';
  import ConfigTemplate from './modules/Index.vue'

  export default {
    props: {
      setting: Array,
      settingOrigin : Array,
      fontWeightsprop: Array,
      fontFamilyprop: Array,
    },
    components:{
      ConfigTemplate: ConfigTemplate    
    },    
    setup(props, { emit }){
      const shopDomain = ref(store.state.auth.shopifyDomain)
      const changeBtnColor = ref()
      const backendLink = ref()
      backendLink.value = process.env.VUE_APP_BACKEND_LINK
      const settings = ref(props.setting)
      const settingsOrigin = ref(props.settingOrigin)
      const fontFamily = ref(props.fontFamilyprop)
      const fontWeights = ref(props.fontWeightsprop)
      const faqMorePageSetting = ref()
      faqMorePageSetting.value = store.state.data.faq_more_page_setting
      watch(settings.value,settingsOrigin.value, () => {
        emit('data', {settings, settingsOrigin})
      })

      return{
        shopDomain,
        changeBtnColor,
        backendLink,
        fontFamily,
        fontWeights,
        faqMorePageSetting,
        settings,
        settingsOrigin,
      }
    }
  }
</script>
<style>
  .placeholder-color::placeholder {color:var(--placeholder-color)}
  .placeholder-color:-ms-input-placeholder {color:var(--placeholder-color) ;}
  .placeholder-color::-ms-input-placeholder {color:var(--placeholder-color);}
  @media only screen and (max-width: 575px) {
    #category-nav{
      margin: 0 !important;
      margin-top: 20px !important;
    }
  }
</style>