<template>
  <div class="bg-white">
    <div class="">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button class="nav-link col active fw-bold" id="category" data-bs-toggle="tab" data-bs-target="#categoryid" type="button" role="tab" aria-controls="categoryid" aria-selected="true">Category</button>
          <button class="nav-link col fw-bold" id="question" data-bs-toggle="tab" data-bs-target="#questionid" type="button" role="tab" aria-controls="questionid" aria-selected="false">Question</button>
          <button class="nav-link col fw-bold" id="answer" data-bs-toggle="tab" data-bs-target="#answerid" type="button" role="tab" aria-controls="answerid" aria-selected="false">Answer</button>
          <button class="nav-link col fw-bold" id="custom" data-bs-toggle="tab" data-bs-target="#customid" type="button" role="tab" aria-controls="customid" aria-selected="false">Custom</button>
        </div>
      </nav>
      <div class="tab-content mt-3" id="nav-tabContent">
        <div class="tab-pane fade show active" id="categoryid" role="tabpanel" aria-labelledby="category">
          <div class="pb-2 d-flex justify-content-between align-items-center" v-if="!settings.dont_category_faq && (settings.template_number === 1 || settings.template_number === 2 || settings.template_number === 5 || settings.template_number === 7)">
            <span>Show category bar</span>
            <div class="form-check form-switch" style="margin-right: -10px;">
              <input v-model="settings.show_category_bar" class="form-check-input fs-5" type="checkbox" id="flexSwitchCheckDefault" style="cursor:pointer">
            </div>
          </div>
          <div v-if="settings.show_category_bar">
            <div class="my-3" v-if="(settings.template_number === 1 || settings.template_number === 2 || settings.template_number === 5 || settings.template_number === 7)">
              <span>Style</span>
              <div class="d-flex align-items-center justify-content-center mt-2 border p-3 rounded">
                <div class="rounded py-2 px-3 border bg-white me-4" @click="settings.category_bar_number = 1" style="cursor: pointer;" :class="settings.category_bar_number === 1? 'bgr-primary text-white' : ''">Style 1</div>
                <div class="py-2 px-3 border bg-white rounded" @click="settings.category_bar_number = 2" :class="settings.category_bar_number === 2? 'bgr-primary text-white' : ''" style="cursor: pointer;">Style 2</div>
              </div>
            </div>
            <div class="pt-2" v-if="settings.template_number !== 4932">
              <span v-if="(settings.template_number === 8 || settings.template_number === 4)">Category bar active color</span>
              <span v-else-if="(settings.template_number !== 6)">Category bar background color</span>
              <span v-else>Category bar active background color</span>
              <div class="p-3 border d-flex flex-wrap mt-2" style="border-radius: 5px">
                <div class="d-flex flex-wrap">
                  <input type="color" v-model="settings.category_bar_background" class="rounded p-0 m-1" style="height: 34px; width: 34px; border: none">
                  <div class="d-flex align-items-center m-1">
                    <input class="form-control" :placeholder="settings.category_bar_background" v-model="settings.category_bar_background" style="height: 34px; width: 150px" type="text">
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-2" v-if="settings.template_number !== 4932">
              <span>Category bar font color</span>
              <div class="p-3 border d-flex flex-wrap mt-2" style="border-radius: 5px">
                <div class="d-flex flex-wrap">
                  <input type="color" v-model="settings.category_bar_color" class="rounded p-0 m-1" style="height: 34px; width: 34px; border: none">
                  <div class="d-flex align-items-center m-1">
                    <input class="form-control" :placeholder="settings.category_bar_color" v-model="settings.category_bar_color" style="height: 34px; width: 150px" type="text">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="py-2">
            <label for="fontsize" class="form-label m-0">Font Size: {{ settings.category_font_size }}</label>
            <input type="range" class="form-range" id="fontsize" v-model="settings.category_font_size" min="10" max="40">
          </div>
          <div class="py-2">
            <label for="fontweight">Font family</label>
              <select class="form-select mt-1" id="fontweight"  style="cursor:pointer" aria-label="Default select example" v-model="settings.category_font_family">
                <option v-for="font in fontFamily" :key="font" :value="`${font}`" :style="`font-family: ${font}`">{{ font == 'unset' ? 'Use your store font (not available in live preview)' : font }}</option>
              </select>
          </div>
          <div class="py-2" v-if="settings.template_number !== 4931 && settings.template_number !== 4932">
            <label for="fontweight">Font Weight</label>
              <select class="form-select mt-1 text-capitalize" style="cursor:pointer" id="fontweight" aria-label="Default select example" v-model="settings.category_font_weight">
                <option v-for="fontw in fontWeights" class="text-capitalize" :key="fontw" :value="`${fontw}`">{{fontw}}</option>
              </select>
          </div>
          <div class="py-2" v-if="settings.template_number !== 4931 && settings.template_number !== 4932">
            <label for="fontweight">Text Style</label>
            <select class="form-select mt-1" id="fontweight" style="cursor:pointer" aria-label="Default select example" v-model="settings.category_text_style">
              <option value="lowercase">Lowercased </option>
              <option value="unset">Normal </option>
              <option value="uppercase">Uppercased </option>
              <option value="capitalize">CapiTaliZed </option>
            </select>
          </div>
          <div class="py-2" v-if="settings.template_number !== 4931 && settings.template_number !== 4932">
            <label for="fontweight">Text Align</label>
            <select class="form-select mt-1" id="fontweight" style="cursor:pointer" aria-label="Default select example" v-model="settings.category_text_align">
              <option value="left">Left</option>
              <option value="center">Center</option>
              <option value="right">Right</option>
            </select>
          </div>
          <div class="pt-2">
            <span>Font color</span>
            <div class="p-3 border d-flex flex-wrap mt-2" style="border-radius: 5px">
              <div class="d-flex flex-wrap">
                <input type="color" v-model="settings.category_font_color" class="rounded p-0 m-1" style="height: 34px; width: 34px; border: none">
                <div class="d-flex align-items-center m-1">
                  <input class="form-control" :placeholder="settings.category_font_color" v-model="settings.category_font_color" style="height: 34px; width: 150px" type="text">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="questionid" role="tabpanel" aria-labelledby="question">
          <div class="tab-pane fade show active" id="questionid" role="tabpanel" aria-labelledby="question">
            <div>
              <label for="fontsize" class="form-label m-0">Font Size: {{ settings.faq_font_size }}</label>
              <input type="range" class="form-range" id="fontsize" v-model="settings.faq_font_size"  :min="10" :max="40">
            </div>
            <div class="py-2" v-if="settings.template_number !== 4931">
              <label for="fontweight">Font Weight</label>
              <select class="form-select mt-1 text-capitalize" style="cursor:pointer" id="fontweight" aria-label="Default select example" v-model="settings.faq_font_weight">
                <option v-for="fontw in fontWeights" class="text-capitalize" :key="fontw" :value="`${fontw}`">{{fontw}}</option>
              </select>
            </div>
            <div class="py-2">
              <label for="fontweight">Font family</label>
              <select class="form-select mt-1" id="fontweight" style="cursor:pointer" aria-label="Default select example" v-model="settings.faq_font_family">
                <option v-for="font in fontFamily" :key="font" :value="`${font}`" :style="`font-family: ${font}`">{{ font == 'unset' ? 'Use your store font (not available in live preview)' : font }}</option>
              </select>
            </div>
            <div class="py-2" v-if="(settings.template_number === 2 || settings.template_number === 5)">
              <span>Background color</span>
              <div class="p-3 border d-flex mt-2" style="border-radius: 5px">
                <div class="d-flex flex-wrap">
                  <input type="color" v-model="settings.faq_bg_color" class="rounded p-0 m-1" style="height: 34px; width: 34px; border: none">
                  <div class="d-flex align-items-center m-1">
                    <input class="form-control" :placeholder="settings.faq_bg_color" v-model="settings.faq_bg_color" style="height: 34px; width: 150px" type="text">
                  </div>
                </div>
              </div>
            </div>
            <div class="py-2">
              <span>Font color</span>
              <div class="p-3 border d-flex mt-2" style="border-radius: 5px">
                <div class="d-flex flex-wrap">
                  <input type="color" v-model="settings.faq_font_color" class="rounded p-0 m-1" style="height: 34px; width: 34px; border: none">
                  <div class="d-flex align-items-center m-1">
                    <input class="form-control" :placeholder="settings.faq_font_color" v-model="settings.faq_font_color" style="height: 34px; width: 150px" type="text">
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-2" v-if="(settings.template_number === 2)">
              <span>Hover color</span>
              <div class="p-3 border d-flex flex-wrap mt-2" style="border-radius: 5px">
                <div class="d-flex flex-wrap" >
                  <input type="color" v-model="settings.faq_hover_color" class="rounded p-0 m-1" style="height: 34px; width: 34px; border: none">
                  <div class="d-flex align-items-center m-1">
                    <input class="form-control" :placeholder="settings.faq_hover_color" v-model="settings.faq_hover_color" style="height: 34px; width: 150px" type="text">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="answerid" role="tabpanel" aria-labelledby="answer">
          <div class="py-2">
            <div>
              <label for="fontsize" class="form-label m-0">Font Size: {{ settings.answer_font_size }}</label>
              <input type="range" class="form-range" id="fontsize" v-model="settings.answer_font_size"  :min="10" :max="40">
            </div>
            <div class="py-2">
              <label for="fontweight">Font family</label>
              <select class="form-select mt-1" id="fontweight" style="cursor:pointer" aria-label="Default select example" v-model="settings.answer_font_family">
                <option v-for="font in fontFamily" :key="font" :value="`${font}`" :style="`font-family: ${font}`">{{ font == 'unset' ? 'Use your store font (not available in live preview)' : font }}</option>
              </select>
            </div>
            <div class="py-2" v-if="settings.template_number !== 4931">
              <label for="fontweight">Font Weight</label>
              <select class="form-select mt-1 text-capitalize" style="cursor:pointer" id="fontweight" aria-label="Default select example" v-model="settings.answer_font_weight">
                <option v-for="fontw in fontWeights" class="text-capitalize" :key="fontw" :value="`${fontw}`">{{fontw}}</option>
              </select>
            </div>
            <div class="py-2"  v-if="(settings.template_number === 2 || settings.template_number === 5)">
              <span>Background color</span>
              <div class="p-3 border d-flex mt-2" style="border-radius: 5px">
                <div class="d-flex flex-wrap">
                  <input type="color" v-model="settings.answer_bg_color" class="rounded p-0 m-1" style="height: 34px; width: 34px; border: none">
                  <div class="d-flex align-items-center m-1">
                    <input class="form-control" :placeholder="settings.answer_bg_color" v-model="settings.answer_bg_color" style="height: 34px; width: 150px" type="text">
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-2">
              <span>Font color</span>
              <div class="p-3 border d-flex align-items-end mt-2" style="border-radius: 5px">
                <div class="d-flex flex-wrap">
                  <input type="color" v-model="settings.answer_font_color" class="rounded p-0 m-1" style="height: 34px; width: 34px; border: none">
                  <div class="d-flex align-items-center m-1">
                    <input class="form-control" :placeholder="settings.answer_font_color" v-model="settings.answer_font_color" style="height: 34px; width: 150px" type="text">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade p-3 border" style="border-radius: 10px" id="customid" role="tabpanel" aria-labelledby="custom">
          <div>
            <span>CSS</span>
          </div>
          <div class="overflow-auto">
            <textarea 
              placeholder=".faq-page-title{
color: #fff;
margin:0; 
padding: 20px 0; 
font-weight: 600;
}"
              v-model="settings.custom_css" class="w-100 border px-2 " style="border-radius: 10px; outline: none" rows="3"></textarea>
          </div>
          <span style="color: #position">This will be applicable to your entire FAQ page. As this is page related setting, changes you make here won't be reflected on live view on left side. Please don't touch this section if you don't know what you are doing. Feel free to contact our support and we are more than welcome to help.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from 'vue'
  import store from '../../../../store';

  export default {
    props: {
      setting: Array,
      settingOrigin : Array,
      fontWeightsprop: Array,
      fontFamilyprop: Array,
    },
    setup(props, { emit }){
      const settings = ref(props.setting)
      const fontFamily = ref(props.fontFamilyprop)
      const fontWeights = ref(props.fontWeightsprop)
      const faqMorePageSetting = ref()
      faqMorePageSetting.value = store.state.data.faq_more_page_setting
      watch(settings.value, () => {
        emit('data', {settings})
      })
      return{
        faqMorePageSetting,
        settings,
        fontFamily,
        fontWeights
      }
    }
  }
</script>
<style>
</style>