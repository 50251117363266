<template>
  <div style="word-wrap: break-word;">
    <div class="px-4 pb-4">
      <div class="d-flex justify-content-between position-sticky-menu-embeded border-bottom py-3">
        <div class="d-flex align-items-center text-start">
          <a @click="$router.go(-1)" class="text-decoration-none" style="cursor:pointer">
            <i class="fas fa-long-arrow-alt-left rounded-3 fs-4 d-flex justify-content-center align-items-center me-3" style="border:1px solid #9ea5ac; height: 40px; width: 40px; color: #5c5f62"></i>
          </a>
          <div class="fs-5 fw-bolder text-nowrap">Documentation</div>
        </div>
        <div class="d-flex align-items-center">
          <div class="d-none d-sm-block" @click="showDocumentItem('menu')">
            <div style="cursor: pointer" class="d-flex align-items-center justify-content-center hover-color me-3 fw-bold" @mouseover="changeColorIconAdd = true" @mouseleave="changeColorIconAdd = false">
              <!-- <svg :fill="changeColorIconAdd === true? '#FF8200' : ''" class="Polaris-Icon__Svg hover-color"  width="22" height="22" viewBox="0 0 512 512"><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM256 336c-18 0-32 14-32 32s13.1 32 32 32c17.1 0 32-14 32-32S273.1 336 256 336zM289.1 128h-51.1C199 128 168 159 168 198c0 13 11 24 24 24s24-11 24-24C216 186 225.1 176 237.1 176h51.1C301.1 176 312 186 312 198c0 8-4 14.1-11 18.1L244 251C236 256 232 264 232 272V288c0 13 11 24 24 24S280 301 280 288V286l45.1-28c21-13 34-36 34-60C360 159 329 128 289.1 128z"/></svg> -->
              <div @click="changeRouter" :style="page === 'menu'? 'color: #ff8200; border-bottom: 1px solid #ff8200' : ''" style="padding: 6px;">Add Faq page to Menu</div>
            </div>
          </div>
          <div class="d-none d-md-block" @click="showDocumentItem('product')">
            <div style="cursor: pointer" class="d-flex align-items-center justify-content-center hover-color me-3 fw-bold" @mouseover="changeColorIconAdd = true" @mouseleave="changeColorIconAdd = false">
              <!-- <svg :fill="changeColorIconAdd === true? '#FF8200' : ''" class="Polaris-Icon__Svg hover-color"  width="22" height="22" viewBox="0 0 512 512"><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM256 336c-18 0-32 14-32 32s13.1 32 32 32c17.1 0 32-14 32-32S273.1 336 256 336zM289.1 128h-51.1C199 128 168 159 168 198c0 13 11 24 24 24s24-11 24-24C216 186 225.1 176 237.1 176h51.1C301.1 176 312 186 312 198c0 8-4 14.1-11 18.1L244 251C236 256 232 264 232 272V288c0 13 11 24 24 24S280 301 280 288V286l45.1-28c21-13 34-36 34-60C360 159 329 128 289.1 128z"/></svg> -->
              <div class="d-none d-sm-block" @click="changeRouter" :style="page === 'product'? 'color: #ff8200; border-bottom: 1px solid #ff8200' : ''" style="padding: 6px;">Add Product FAQs block</div>
            </div>
          </div>
          <div class="d-none d-lg-block" @click="showDocumentItem('more-page')">
            <div style="cursor: pointer" class="d-flex align-items-center justify-content-center hover-color me-3 fw-bold" @mouseover="changeColorIconAdd = true" @mouseleave="changeColorIconAdd = false">
              <!-- <svg :fill="changeColorIconAdd === true? '#FF8200' : ''" class="Polaris-Icon__Svg hover-color"  width="22" height="22" viewBox="0 0 512 512"><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM256 336c-18 0-32 14-32 32s13.1 32 32 32c17.1 0 32-14 32-32S273.1 336 256 336zM289.1 128h-51.1C199 128 168 159 168 198c0 13 11 24 24 24s24-11 24-24C216 186 225.1 176 237.1 176h51.1C301.1 176 312 186 312 198c0 8-4 14.1-11 18.1L244 251C236 256 232 264 232 272V288c0 13 11 24 24 24S280 301 280 288V286l45.1-28c21-13 34-36 34-60C360 159 329 128 289.1 128z"/></svg> -->
              <div class="d-none d-sm-block" @click="changeRouter" :style="page === 'more-page'? 'color: #ff8200; border-bottom: 1px solid #ff8200' : ''" style="padding: 6px;">Add FAQs on other pages</div>
            </div>
          </div>
          <div class="dropdown d-block d-lg-none ms-4">
            <button class="btn dropdown-toggle border bg-white" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              <span>More actions</span>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <div class="d-sm-none" @click="showDocumentItem('menu')">
                <div style="cursor: pointer" class="hover-color p-2 text-nowrap text-start" @mouseover="changeColorIconAdd = true" @mouseleave="changeColorIconAdd = false">
                  <div @click="changeRouter" :style="page === 'menu'? 'color: #ff8200' : ''" style="padding: 6px;">Add Faq page to Menu</div>
                </div>
              </div>
              <div class="d-md-none" @click="showDocumentItem('product')">
                <div style="cursor: pointer" class="hover-color p-2 text-nowrap text-start" @mouseover="changeColorIconAdd = true" @mouseleave="changeColorIconAdd = false">
                  <div @click="changeRouter" :style="page === 'product'? 'color: #ff8200' : ''" style="padding: 6px;">Add Product FAQs block</div>
                </div>
              </div>
              <div class="d-lg-none" @click="showDocumentItem('more-page')">
                <div style="cursor: pointer" class="hover-color p-2 text-nowrap text-start" @mouseover="changeColorIconAdd = true" @mouseleave="changeColorIconAdd = false">
                  <div @click="changeRouter" :style="page === 'more-page'? 'color: #ff8200' : ''" style="padding: 6px;">Add FAQs on other pages</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReturnExchange class=""></ReturnExchange>
      <div>
        <div>
          <AddToMenu v-if="page === 'menu'" id="add-to-menu"></AddToMenu>
        </div>
        <div>
          <AddBlockShopify v-if="page === 'product'" id="add-block-product" class="mt-3"></AddBlockShopify>
        </div>
        <div>
          <AddBlockMorePage v-if="page === 'more-page'" id="add-block-more-page" class="mt-3"></AddBlockMorePage>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import AddBlockShopify from './AddBlockShopify.vue';
  import AddBlockMorePage from './AddBlockMorePage.vue';
  import AddToMenu from './AddToMenu.vue'
  import { ref } from 'vue'
  import ReturnExchange from './../recomment-app/Return-Exchange.vue'

  export default {
    components: {
      AddBlockShopify: AddBlockShopify,
      AddBlockMorePage: AddBlockMorePage,
      AddToMenu: AddToMenu,
      ReturnExchange: ReturnExchange

    },
    setup() {
      const page = ref()
      page.value = 'menu'
      if(location.href.indexOf('?page=') > 0){
        page.value = location.href.slice(location.href.indexOf('?page=') + 6)
      }
      const showDocumentItem = (text) => {
        page.value = text
      }
      return{
        page,
        showDocumentItem
      }
    }
  }
</script>
<style>

</style>