<template>
  <div class="bg-white">
    <div class="d-flex justify-content-between align-items-center">
      <span>Show Button</span>
      <div class="form-check form-switch" style="margin-right: -10px;">
        <input v-model="settings.btn_top_visible" class="form-check-input fs-5" type="checkbox" id="flexSwitchCheckDefault" style="cursor:pointer">
      </div>
    </div>
    <div class="" v-if="settings.btn_top_visible">
      <div class="pt-2">
        <span>Button Background Color</span> 
        <div class="p-3 border d-flex flex-wrap mt-2" style="border-radius: 5px">
          <div class="d-flex flex-wrap">
            <input type="color" v-model="settings.btn_top_background" class="rounded p-0 m-1" style="height: 34px; width: 34px; border: none">
            <div class="d-flex align-items-center m-1">
              <input class="form-control" :placeholder="settings.btn_top_background" v-model="settings.btn_top_background" style="height: 34px; width: 150px" type="text">
            </div>
          </div>
        </div>
      </div>
      <div class="pt-2">
        <span>Button Hover Color</span> 
        <div class="p-3 border d-flex flex-wrap mt-2" style="border-radius: 5px">
          <div class="d-flex flex-wrap">
            <input type="color" v-model="settings.btn_top_hover" class="rounded p-0 m-1" style="height: 34px; width: 34px; border: none">
            <div class="d-flex align-items-center m-1">
              <input class="form-control" :placeholder="settings.btn_top_hover" v-model="settings.btn_top_hover" style="height: 34px; width: 150px" type="text">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from 'vue'
  export default {
    props: {
        setting: Array,
      },
      setup(props, { emit }){
        const settings = ref(props.setting)
        watch(settings.value, () => {
          emit('data', {settings})
        })
        return{
          settings,
        }
      }
  }
</script>
<style>
</style>