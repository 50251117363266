<template>
  <div class="mb-3" id="header-config">
    <div class="d-flex flex-column flex-lg-row">
      <div class="col-12 col-lg-4">
        <div class="me-0 me-lg-3 product-scroll" style="line-height: 1.8; height: calc(100vh - 108px); overflow-y: auto;position: sticky; top: 130px;">
          <ConfigTemplate
            :setting="settings" 
            :settingOrigin="settingsOrigin"
            :fontFamilyprop="fontFamily"
            :fontWeightsprop="fontWeights"
          />
        </div>
      </div>
      <div class="col-12 col-lg-8 mt-3 mt-lg-0">
        <div class="bg-white border" style="border-radius: 10px; line-height: 1.8 !important">
          <div class="border-bottom fw-bold d-flex flex-column flex-md-row" style="padding: 16px 24px">
            <span class="col fs-6">Live preview</span>
            <div class="col-md-8 col-12 text-start d-flex">
              <a target="_blank" :href="`https://${shopDomain}${settings.faq_page_url}`" class="d-flex align-items-center m-auto text-color-primary" style="text-decoration: none;">
                <span style="word-break:break-word">https://{{ shopDomain }}{{settings.faq_page_url}}
                  <i class="fa-solid fa-arrow-up-right-from-square ms-1"></i>
                </span>
              </a>
            </div>
            <span class="col d-none d-md-block"></span>
          </div>
          <div class="d-flex flex-sm-row flex-column mt-3 px-4" style="max-width: ;">
            <label class="d-flex fw-bold me-3" style="white-space: nowrap;" for="height"><span>Header height: </span> {{settings.banner_height}}px</label>
            <input class="form-range" type="range" min="0" max="460" step="2" id="height" v-model="settings.banner_height">
          </div>
          <div class="m-4 border" style="position: relative;" :style="`background-color: ${settings.page_background_color}`">
            <div class="" v-if="settings.banner_visible">
              <div class="d-flex flex-column justify-content-between" :style="settings.image_banner? `background-image: url(${backendLink}/var/images/banner/${settings.image_banner}); background-size: cover; background-position: center; min-height: calc(${settings.banner_height}px)` : `background-image: url(${backendLink}/var/images/banner/${settings.banner_default}); background-size: cover; background-position: center;min-height: calc(${settings.banner_height}px)`">
                <div>
                  <div v-if="settingsOrigin.show_page_title" class="text-center" :style="`padding-top: ${settings.page_title_paddingtop}px; padding-bottom: ${settings.page_title_paddingbottom}px; color: ${settings.page_title_color}; font-family: ${settings.page_title_font}`">
                    <div :style="`font-size: ${settings.page_title_fontsize}px`" class="m-0" style="font-weight: 600">{{ JSON.parse(settingOrigin.page_title_content)[0].content }}</div>
                  </div>
                  <div class="text-center w-100" :style="settingsOrigin.show_page_title? '' : 'margin-top: 70px'" v-if="settings.show_search_input && settings.search_input_style == 1">
                    <input type="text" class="form-control m-auto fs-6 placeholder-color" ::placeholder="settings.placeholder_color" :placeholder-color="settings.placeholder_color" :placeholder="JSON.parse(settingOrigin.search_placehoder)[0].content" style="box-shadow: none; width: 70%; line-height:1 !important; padding: 16px;"  :style="`font-family: ${settings.search_placeholder_font};--placeholder-color: ${settings.placeholder_color}; color: ${settings.placeholder_color}`">
                  </div>
                  <div class="text-center w-100" :style="settingsOrigin.show_page_title? '' : 'margin-top: 70px'" v-if="settings.show_search_input && settings.search_input_style == 2">
                    <div class="border m-auto" style="position:relative; width: 70%; border-radius: 100px;">
                      <input type="text" class="fs-6 placeholder-color" :placeholder-color="settings.placeholder_color" id="" :placeholder="JSON.parse(settingOrigin.search_placehoder)[0].content" style="box-shadow: none; outline: none; border:none; border-radius: 100px;width: 100%; padding: 16px 16px 16px 50px; line-height:1" :style="`font-family: ${settings.search_placeholder_font};--placeholder-color: ${settings.placeholder_color}; color: ${settings.placeholder_color}`">
                      <i class="fa-solid fw-none fa-magnifying-glass fs-5" style="position:absolute; top: 50%; left: 0; transform: translateY(-50%); margin-left: 17px;" :style="`color: ${settings.micro_scope_color}`"></i>
                    </div>
                  </div>
                </div>
                <div v-if="settingsOrigin.show_intro_text"  class="text-center" :style="`padding-top: ${settings.intro_text_paddingtop}px; padding-bottom: ${settings.intro_text_paddingbottom}px; color: ${settings.intro_text_color}; font-size: ${settings.intro_text_fontsize}px; font-family: ${settings.intro_text_font}`">
                  <span v-html="JSON.parse(settingOrigin.intro_text_content)[0].content"></span>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="d-flex flex-column justify-content-between" :style="`min-height: ${settings.banner_height}px`">
                <div>
                  <div v-if="settingsOrigin.show_page_title" class="text-center" :style="`padding-top: ${settings.page_title_paddingtop}px; padding-bottom: ${settings.page_title_paddingbottom}px; color: ${settings.page_title_color}; font-family: ${settings.page_title_font}`">
                    <div :style="`font-size: ${settings.page_title_fontsize}px`" class="m-0" style="font-weight: 600">{{ JSON.parse(settingOrigin.page_title_content)[0].content }}</div>
                  </div>
                  <div class="text-center w-100" :style="settingsOrigin.show_page_title? '' : 'margin-top: 70px'" v-if="settings.show_search_input && settings.search_input_style == 1">
                    <input type="text" class="form-control m-auto fs-6 placeholder-color" :placeholder-color="settings.placeholder_color" :placeholder="JSON.parse(settingOrigin.search_placehoder)[0].content" ::placeholder="settings.placeholder_color" style="box-shadow: none; width: 70%; line-height:1 !important; padding: 16px"  :style="`font-family: ${settings.search_placeholder_font};--placeholder-color: ${settings.placeholder_color}; color: ${settings.placeholder_color}`">
                  </div>
                  <div class="text-center w-100" :style="settingsOrigin.show_page_title? '' : 'margin-top: 70px'" v-if="settings.show_search_input && settings.search_input_style == 2">
                    <div class="border m-auto" style="position:relative; width: 70%; border-radius: 100px;">
                      <input type="text" class="fs-6 placeholder-color" id="" :placeholder="JSON.parse(settingOrigin.search_placehoder)[0].content" :placeholder-color="settings.placeholder_color" style="box-shadow: none; outline: none; border:none; border-radius: 100px;width: 100%; padding: 16px 16px 16px 50px; line-height:1" :style="`font-family: ${settings.search_placeholder_font}; --placeholder-color: ${settings.placeholder_color}; color: ${settings.placeholder_color}`">
                      <i class="fa-solid fw-none fa-magnifying-glass fs-5" style="position:absolute; top: 50%; left: 0; transform: translateY(-50%); margin-left: 17px;" :style="`color: ${settings.micro_scope_color}`"></i>
                    </div>
                  </div>
                </div>
                <div v-if="settingsOrigin.show_intro_text"  class="text-center" :style="`padding-top: ${settings.intro_text_paddingtop}px; padding-bottom: ${settings.intro_text_paddingbottom}px; color: ${settings.intro_text_color}; font-size: ${settings.intro_text_fontsize}px; font-family: ${settings.intro_text_font}`">
                  <span v-html="JSON.parse(settingOrigin.intro_text_content)[0].content"></span>
                </div>
              </div>
            </div>
            <div class="m-auto" style="max-width: 800px">
              <div class="" style="padding: 30px 16px">
                <div v-if="!settingsOrigin.dont_category_faq && settings.show_category_bar && settings.category_bar_number === 1" class="d-flex justify-content-center flex-wrap" style="margin-bottom: 20px">
                  <div class="border rounded px-2 py-1 fs-6" :style="`font-family: ${settings.category_font_family};color:${settings.category_bar_color}; background-color: ${settings.category_bar_background}; font-weight: ${settings.category_font_weight}; text-transform: ${settings.category_text_style}`" style="margin: 6px; cursor:pointer">Placing an Order</div>
                  <div class="border rounded px-2 py-1 fs-6" :style="`font-family: ${settings.category_font_family};color:${settings.category_bar_color}; background-color: ${settings.category_bar_background}; font-weight: ${settings.category_font_weight}; text-transform: ${settings.category_text_style}`" style="margin: 6px; cursor:pointer">Refunds</div>
                </div>
                <div v-if="!settingsOrigin.dont_category_faq && settings.show_category_bar && settings.category_bar_number === 2" class="d-flex justify-content-center flex-wrap" style="margin-bottom: 20px">
                  <div class="border px-3 py-2 fs-6" :style="`font-family: ${settings.category_font_family};color:${settings.category_bar_color}; background-color: ${settings.category_bar_background}; font-weight: ${settings.category_font_weight}; text-transform: ${settings.category_text_style}`" style="margin: 6px; cursor:pointer; border-radius: 50px;">Placing an Order</div>
                  <div class="border px-3 py-2 fs-6" :style="`font-family: ${settings.category_font_family};color:${settings.category_bar_color}; background-color: ${settings.category_bar_background}; font-weight: ${settings.category_font_weight}; text-transform: ${settings.category_text_style}`" style="margin: 6px; cursor:pointer; border-radius: 50px;">Refunds</div>
                </div>
                <div style="padding-top: 20px">
                  <div style="margin-bottom: 40px">
                    <div v-if="!settingsOrigin.dont_category_faq" :style="`text-align: ${settings.category_text_align}; padding-bottom: 10px`">
                      <span class="border-bottom" :style="`font-size: ${settings.category_font_size}px; color: ${settings.category_font_color}; font-family: ${settings.category_font_family}; font-weight: ${settings.category_font_weight}; text-transform: ${settings.category_text_style}`">Placing an Order</span>
                    </div>
                    <div style="margin-bottom: 16px">
                      <div class="pt-2">
                        <span :style="`color: ${settings.faq_font_color}; font-weight: ${settings.faq_font_weight}; font-family: ${settings.faq_font_family}; font-size: ${settings.faq_font_size}px`">How do I redeem my One 4 All card?</span>
                      </div>
                      <span class="d-flex" :style="`font-size: ${settings.answer_font_size}px; font-weight: ${settings.answer_font_weight}; color: ${settings.answer_font_color}; font-family: ${settings.answer_font_family}`">We are currently accepting One 4 All cards instore only. Please retain your card after making your purchase, as should you wish to return any items bought using a One 4 All card, we will use this payment method to refund you.</span>
                    </div>
                    <div style="margin-bottom: 16px">
                      <div class="d-flex align-items-center justify-content-between pt-2">
                        <span class="" :style="`color: ${settings.faq_font_color}; font-weight: ${settings.faq_font_weight}; font-family: ${settings.faq_font_family}; font-size: ${settings.faq_font_size}px`">When will I be charged for my order?</span>
                      </div>
                      <span class="d-flex" :style="`font-size: ${settings.answer_font_size}px; font-weight: ${settings.answer_font_weight}; color: ${settings.answer_font_color}; font-family: ${settings.answer_font_family}`">Payment is taken at the point of ordering. If for any reason your item is not dispatched we will ensure you are refunded within 3-5 working days.</span>
                    </div>                    
                  </div>
                  <div style="margin-bottom: 40px">
                    <div v-if="!settingsOrigin.dont_category_faq" :style="`text-align: ${settings.category_text_align}; padding-bottom: 10px`">
                      <span class="border-bottom" :style="`font-size: ${settings.category_font_size}px; color: ${settings.category_font_color}; font-family: ${settings.category_font_family}; font-weight: ${settings.category_font_weight}; text-transform: ${settings.category_text_style}`">Refunds</span>
                    </div>
                    <div style="margin-bottom: 16px">
                      <div class="d-flex align-items-center justify-content-between ">
                        <span class="pt-2" :style="`color: ${settings.faq_font_color}; font-weight: ${settings.faq_font_weight}; font-family: ${settings.faq_font_family}; font-size: ${settings.faq_font_size}px`">I have returned my order – will you refund my delivery?</span>
                      </div>
                      <span class="d-flex" :style="`font-size: ${settings.answer_font_size}px; font-weight: ${settings.answer_font_weight}; color: ${settings.answer_font_color}; font-family: ${settings.answer_font_family}`">If you have returned your full order within 30 days of receipt, we will refund the full amount, including delivery costs. Please note, delivery costs are refunded additionally and will show as a separate refund.</span>
                    </div>                  
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-top: 30px; position: relative">
              <div v-if="settingsOrigin.show_footer_text" :style="`color: ${settings.footer_text_color}; padding-top: ${settings.footer_text_paddingtop}px; padding-bottom: ${settings.footer_text_paddingbottom}px; font-size: ${settings.footer_text_fontsize}px; font-family: ${settings.footer_text_font};`">
                <a class="text-break d-flex align-items-center justify-content-center" href="#footer-config" :style="`text-decoration: none; color: ${settings.footer_text_color}`">
                  <span>{{ JSON.parse(settingOrigin.footer_text_content)[0].content }}</span>              
                </a>
              </div>
              <div v-if="settingsOrigin.yanet_logo_visible" style="padding-bottom: 10px; text-align: center;">
                <span style="color: #4b4b4b; font-size: 15px; text-decoration: none; text-decoration: none; font-family: 'Times New Roman', Times, serif;">Powered by Yanet</span>
              </div>
              <div @mousemove="changeBtnColor = true" @mouseleave="changeBtnColor = false" v-if="settings.btn_top_visible" class="btn-top d-flex justify-content-center align-items-center" style="height: 50px; width: 50px; border-radius: 4px; cursor: pointer; position: absolute; bottom: 10px; right: 10px;" :style="changeBtnColor === true? `background-color: ${settings.btn_top_hover}` : `background-color: ${settings.btn_top_background}`">
                <i class="fa-solid fa-angles-up" style="color: #ffffff"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from 'vue'
  import store from '../../../store';
  import ConfigTemplate from './modules/Index.vue'
  export default {
    props: {
      setting: Array,
      settingOrigin : Array,
      fontWeightsprop: Array,
      fontFamilyprop: Array,
    },
    components:{
      ConfigTemplate: ConfigTemplate
    },
    setup(props, { emit }){
      const changeBtnColor = ref()
      const shopDomain = ref(store.state.auth.shopifyDomain)
      const backendLink = ref()
      backendLink.value = process.env.VUE_APP_BACKEND_LINK
      const settings = ref(props.setting)
      const settingsOrigin = ref(props.settingOrigin)
      const fontFamily = ref(props.fontFamilyprop)
      const fontWeights = ref(props.fontWeightsprop)
      const faqMorePageSetting = ref()
      faqMorePageSetting.value = store.state.data.faq_more_page_setting
      watch(settings.value,settingsOrigin.value, () => {
        emit('data', {settings, settingsOrigin})
      })
      return{
        shopDomain,
        changeBtnColor,
        backendLink,
        fontFamily,
        fontWeights,
        faqMorePageSetting,
        settings,
        settingsOrigin
      }
    }
  }
</script>
<style>
  .placeholder-color::placeholder {color:var(--placeholder-color)}
  .placeholder-color:-ms-input-placeholder {color:var(--placeholder-color) ;}
  .placeholder-color::-ms-input-placeholder {color:var(--placeholder-color);}
</style>