import Admin from './../../components/admin/Admin.vue'
import AdminLogin from './../../components/admin/AdminLogin.vue'
import Merchant from './../../components/admin/Merchant.vue'
import RatingSuggest from './../../components/admin/Rating&Suggest.vue'

const adminRoutes = [
  {
    path: "/admin/login",
    name: "admin-login",
    component: AdminLogin,
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    meta: { admin: true },
    children: [
      {
        path: "/merchant",
        name: "merchant",
        component: Merchant
      },      
      {
        path: "/rating-suggest",
        name: "rating-suggest",
        component: RatingSuggest
      },      
    ]
  },  
]

export default adminRoutes