import { createStore } from "vuex";
import { auth } from "./modules/auth";
import { data } from "./modules/data";
import { plan } from "./modules/plan"

const store = createStore({
  modules: {
    auth,
    data,
    plan,
  },
});
export default store;