<template>
  <div v-if="showImage" class="m-auto" style="position: relative;">
    <a target="_blank" class="d-flex justify-content-center m-auto w-100" href="https://apps.shopify.com/yanet-return-and-exchange?utm_source=yanet+faq&utm_medium=banner">
      <img style="" src="../../../public/Banner-return-app.png" class="mb-2 w-100">
    </a>
    <i @click="changeShowBanner" class="fa-solid fa-rectangle-xmark btn-close-image" style="position: absolute"></i>
  </div>
</template>
<script>
  import { ref  } from 'vue';
  import store from './../../store'
  export default {
    setup() {
      const showImage = ref(store.state.data.showBannerReturn)
      const changeShowBanner = () => {
        showImage.value = false
        store.dispatch('data/setShowBannerReturn', showImage.value)      
      }
      return {
        changeShowBanner,
        showImage
      }
    },
  };
</script>
<style>
  .btn-close-image{
    font-size: 28px;
    top: 10px; 
    right: 10px;
    color: #000;
    cursor: pointer;
  }
  @media only screen and (max-width: 800px) {
    .btn-close-image{
      font-size: 22px;
      top: 7px; 
      right: 7px
    }
  }
  @media only screen and (max-width: 500px) {
    .btn-close-image{
      font-size: 16px;
      top: 4px; 
      right: 4px
    }
  }
</style>