import http from "../http-common";
class PlanDataService {
  create(data) {
    return http.post("/plan", data);
  }
  getAll() {
    return http.get("/plan/getAll");
  }
  get() {
    return http.get("/plan/get");
  }
  update(data) {
    return http.put("/plan/update", data);
  }
  selectPlan(data) {
    return http.put("/plan/select", data);
  }
  chargePlan(data) {
    const query = `?plan=${data.plan}&price=${data.cost}&shopify_plan_id=${data.shopify_plan_id}&shop=${data.shop_domain}&accessToken=${data.token}&redirect=true`
    return http.get(`/select/plan${query}`, data);
  }
}
export default new PlanDataService();