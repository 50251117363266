import ProductFaq from './../../components/products/ProductFaq.vue'
import SetFaqMorePage from './../../components/settings/SetFaqMorePage.vue'

const morePageRoutes = [
  {
    path: "/products-faqs",
    name: "products-faqs",
    component: ProductFaq,
    meta: { auth: true },
  },
  {
    path: "/faq-more-page",
    name: "faq-more-page",
    component: SetFaqMorePage,
    meta: { auth: true },
  },
]

export default morePageRoutes