<template>
  <div class="text-color-secondary">
    <router-view />
  </div>
</template>

<script>
export default {
};
</script>

<style>
#app {
  font-family: -apple-system,BlinkMacSystemFont,San Francisco,Segoe UI,Roboto,Helvetica Neue,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}
</style>
