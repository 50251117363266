import AddCategory from './../../components/categories/AddCategory.vue'
import EditCategory from './../../components/categories/EditCategory.vue'
import StoreCategory from './../../components/categories/StoreCategory.vue'

const categoryRoutes = [
  {
    path: "/categories",
    name: "categories",
    component: StoreCategory,
    meta: { auth: true },
  },
  {
    path: "/add-category",
    name: "add-category",
    component: AddCategory,
    meta: { auth: true },
  },
  {
    path: "/edit-category/:id",
    name: "edit-category",
    component: EditCategory,
    meta: { auth: true },
  },
]

export default categoryRoutes