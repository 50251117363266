<template>
  <div class="pt-3">
    <div class="bg-white p-4 col-12 col-lg-8 m-auto border" style="border-radius: 10px">
      <div class="mb-4">
        <h5 class="modal-title fw-bold" id="staticBackdropLabel">Add FAQ page to your store navigation/menu.</h5>
      </div>
      <div class="mt-3 fs-6">
        <div class="mb-3">
          <p><b class="fs-5">Step 1. </b>
            <span>Visit your store admin panel.</span>            
            <a style="text-decoration: none;" target="_blank" :href="`https://${shopDomain}/admin`"> Go to store admin 
              <i class="fa-solid fa-arrow-up-right-from-square"></i>
            </a>
          </p>
        </div>
        <div class="mb-3">
          <p><b class="fs-5">Step 2.</b>
            <span>
              Click on <b>"Online store"</b> > <b>"Navigation"</b> from left side menu and <b></b>click on <b>"Main menu"</b> or <b>"Footer menu"</b> where you would like to add FAQs page
            </span>
          </p>
          <img src="./../../../public/add-block-shopify/Menu-item-1.png" class="w-100 my-3" alt=""/>
        </div>
        <div class="mb-3">
          <p><b class="fs-5">Step 3.</b> Click <b>"Add menu item"</b>.</p>
          <img src="./../../../public/add-block-shopify/Menu-item-2.png" class="w-100 mb-3" alt=""/>
        </div>
        <div class="mb-3">
          <p><b class="fs-5">Step 4.</b> Enter a menu name you would like along with your FAQ page URL which you got from app and click <b>"Add"</b> <br>
            <span class="text-center">(FAQ page Url: <a target="_blank" class="fw-bold" :href="`https://${shopDomain}/apps/faqs`">https://{{shopDomain}}/apps/faqs</a> or <b>/apps/faqs</b> )</span>
          </p>
          <img src="./../../../public/add-block-shopify/Menu-item-3.png" class="w-100 mb-3" alt=""/>
        </div>
        <p><b class="fs-5">Step 5. </b>Final click <b>"Save"</b> on <b>"Main menu"</b> page and you should be all set.</p>
        <!-- <router-link style="font-weight: 500;padding-bottom: 7px; padding-top: 9px" to="/tutorial" active-class="actived-menu" class="hover-color not-actived-menu text-decoration-none text-color-secondary px-3 d-flex hover-color align-items-center text-black" >
          <span>Plan</span>
        </router-link> -->
      </div>
    </div>
  </div>
</template>
<script>
  import store from "../../store";
  import { ref } from 'vue'
  export default {
    setup() {
      const shopDomain = ref()
      shopDomain.value = store.state.auth.shopifyDomain
      const contact = () => {
        if(document.getElementsByClassName('cc-1hqb')[0]){
          document.getElementsByClassName('cc-1hqb')[0].click()
        }
        else{
          let user_nickname = `[FAQ]${shopDomain.value}`
          window.open(`https://go.crisp.chat/chat/embed/?website_id=35cbcb5a-831c-47fb-9064-0bced009fca9&user_nickname=${user_nickname}`);
        }
      }
      return{
        shopDomain,
        contact,
      }
    }
  }
</script>
<style>

</style>