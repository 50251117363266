import StoreFAQs from './../../components/faqs/StoreFAQs.vue'
import AddFaq from './../../components/faqs/AddFaq.vue'
import EditFaq from './../../components/faqs/EditFaq.vue'
import Analytics from './../../components/analytics/Analytics.vue'

const faqRoutes = [
  {
    path: "/storeFaqs",
    name: "store-faqs",
    component: StoreFAQs,
    meta: { auth: true },
  },
  {
    path: "/add-faq",
    name: "add-faq",
    component: AddFaq,
    meta: { auth: true },
  },
  {
    path: "/edit-faq/:id",
    name: "edit-faq",
    component: EditFaq,
    meta: { auth: true },
  },
  {
    path : "/analytics",
    name : "analytics",
    component : Analytics,
    mete : { auth : true }
  }
]

export default faqRoutes