<template>
  <div class="bg-white" style="border-radius: 10px; cursor: pointer;">
    <div>
      <div @click="contact" class="d-flex justify-content-between align-items-center">
        <span class="fw-bold">Powered by Yanet</span>
        <div class="form-check form-switch" style="margin-right: -10px;">
          <input disabled v-model="settingsOrigin.yanet_logo_visible" class="form-check-input fs-5" type="checkbox" id="flexSwitchCheckDefault" style="cursor:pointer">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from 'vue'
  import store from '../../../../store';

  export default {
    props: {
      settingOrigin: Array,
    },
    setup(props, { emit }){
      const plan = ref(store.state.plan.plan_info.plan)
      const fullFeature = ref(store.state.plan.full_feature)
      const shopDomain = ref(store.state.auth.shopifyDomain)
      const settingsOrigin = ref(props.settingOrigin)
      watch(settingsOrigin.value, () => {
        emit('data', {settingsOrigin})
      })
      const contact = () => {
        if(!fullFeature.value){
          if(document.getElementsByClassName('cc-nsge')[0]){
            document.getElementsByClassName('cc-nsge')[0].click()
          }
          else{
            let user_nickname = `[FAQ]${shopDomain.value}`
            window.open(`https://go.crisp.chat/chat/embed/?website_id=35cbcb5a-831c-47fb-9064-0bced009fca9&user_nickname=${user_nickname}`);
          }
        }
      }
      return{
        plan,
        contact,
        settingsOrigin,
        fullFeature
      }
    }
  }
</script>
<style>
</style>