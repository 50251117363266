<template>
  <div class="bg-white">
    <div>
      <!-- <p class="">FAQ accordian width</p> -->
      <label for="fontsize" class="form-label m-0">Faq page max width in px (not available in live preview): {{ settings.width_faqs_accordian }}px</label>
      <input type="range" aria-valuemax="1800" aria-valuemin="800" step="10" class="form-range" id="fontsize" min="800" max="1800" v-model="settings.width_faqs_accordian">
    </div>
    <div class="form-check">
      <input v-model="settings.set_width_product_faq" class="form-check-input" type="checkbox" id="product-faq-width">
      <label class="form-check-label" for="product-faq-width">
        Product Faq width
      </label>
    </div>
    <div v-if="settings.set_width_product_faq">
      <!-- <p class="">FAQ accordian width</p> -->
      <label for="fontsize" class="form-label m-0 fw-normal">Product Faq max width in px (not available in live preview): {{ settings.width_faqs_product }}px</label>
      <input type="range" aria-valuemax="1800" aria-valuemin="500" step="10" class="form-range" id="fontsize" min="500" max="1800" v-model="settings.width_faqs_product">
    </div>
  </div>
</template>

<script>
  import { ref, watch } from 'vue'
  export default {
    props: {
      setting: Array,
    },
    setup(props, { emit }){
      const settings = ref(props.setting)
            watch(settings.value, () => {
        emit('data', {settings})
      })
      return{
        settings,
      }
    }
  }
</script>
<style>
</style>