<template>
  <div class="fs-6">
    <div class="fs-6 my-2 fw-bold d-flex align-items-center justify-content-between w-100">
      <div class="fw-bold">
        <span>Total: {{ RatingSuggest.length }} Rate</span>
      </div>
      <div class="d-flex align-items-center">
        <div class="d-flex align-items-center">
          <label for="search-merchant">Search</label>
          <input type="email" class="form-control ms-3" @input="searchDomain" v-model="shopifyDomain" id="search-merchant" aria-describedby="emailHelp">
        </div>
        <div class="ms-2">
          <button class="btn bgr-primary border rounded text-white" data-bs-toggle="modal" data-bs-target="#add-review">Add Review</button>
          <div class="modal fade" id="add-review" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Add review</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div>
                    <label for="shopify_domain" class="form-label">Shopify domain</label>
                    <input v-model="shopDomain" type="text" class="form-control" id="shopify_domain" placeholder="example.myshopify.com">
                  </div>
                  <div class="my-3">
                    <label for="star_number" class="form-label">Star number</label>
                    <input v-model="startNumber" type="number" max="5" min="1" class="form-control" id="star_number" placeholder="Start number">
                  </div>
                  <div class="form-floating">
                    <textarea v-model="comment" class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                    <label for="floatingTextarea2">Content</label>
                  </div>                  
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" @click="createReview()" class="btn bgr-primary text-white">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="border bg-white" style="border-radius: 10px">
      <div class="d-flex align-items-center text-center fw-bold p-2">
        <!-- <div class="col-1 px-1">ID</div> -->
        <div class="col-3 px-1">Domain</div>
        <div class="col-2 px-1">Rating star</div>
        <div class="col px-1">Comment</div>
        <div class="col-2 px-1">Time</div>
      </div>
      <div v-if="RatingSuggest.length > 0">
        <div class="d-flex align-items-center text-center border-top p-2" v-for="item in RatingSuggest" :key="item">
          <!-- <div class="col-1 px-1">{{ item.id }}</div> -->
          <div class="col-3 p-1">{{ item.domain }}</div>
          <div class="col-2 p-1">{{ item.star }}</div>
          <div class="col p-1">{{ item.comment }}</div>
          <div class="col-2 p-1">{{ item.createdAt }}</div>
        </div>
      </div>
      <div v-else class="">
        
      </div>
    </div>
  </div>
</template>

<script>
import RatingDataService from '../../services/RatingDataService';
import { ref } from "vue";
import { inject } from 'vue'

export default {
  props: {
    rattingData: Array,
  },
  setup(props){
    const toast = inject('$toast');
    const startNumber = ref(5)
    const comment = ref()
    const shopDomain = ref()
    const RatingSuggest = ref(props.rattingData)
    const shopifyDomain = ref()
    const RatingSuggestOrigin = ref(props.rattingData)
    RatingSuggest.value.forEach(item => {
      let date = ''
      date = new Date(item.createdAt).toString()
      item.createdAt = date.slice(0, date.indexOf('GMT'))
    })

    const searchDomain = () => {
      if(shopifyDomain.value == '' || shopifyDomain.value == undefined){
        RatingSuggest.value = RatingSuggestOrigin.value
      }
      else{
        RatingSuggest.value = RatingSuggestOrigin.value.filter((item)=>{
          return shopifyDomain.value.toLowerCase().split(' ').every(v => item.domain.toLowerCase().includes(v))
        })
      }
    }

    const createReview = async() => {
      let data = {
        domain: shopDomain.value,
        star:  startNumber.value.toString(),
        comment: comment.value
      }
      await RatingDataService.create(data)
      .then(() => {
        toast.open({
          message: "Success!",
          type: "success",
          duration: 3000,
          dismissible: true
        })
        document.getElementsByClassName('btn-secondary')[0].click()
      })
      .catch(() => {
        toast.open({
          message: "Error!",
          type: "error",
          duration: 3000,
          dismissible: true
        })
        document.getElementsByClassName('btn-secondary')[0].click()
      })
    }
    return{
      startNumber,
      comment,
      shopDomain,
      createReview,
      shopifyDomain,
      RatingSuggest,
      searchDomain
    }
  },
  async created() {
  }
}
</script>