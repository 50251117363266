<template>
  <form @submit.prevent="login">
    <div class="d-flex align-items-center justify-content-center bg-white" style="height: 100vh; width: 100vw">
      <div class="p-4 border bg-light col-8 col-md-4" style="border-radius: 10px;">
        <div class="text-center">
          <img src="./../../../public/Logo-App.png" style="height: 50px" class="mb-2">
          <h3 class="text-color-primary pb-3">Professional FAQ Login</h3>
        </div>
        <div class="">
          <label for="user-name" class="form-label fw-bold">Shopify domain<span style="color: red">*</span></label>
          <input type="text" class="form-control" placeholder="store-name or store-name.myshopify.com" id="user-name" v-model="shopifyDomain">
        </div>
        <!-- <div class="text-center mb-3">
          <span style="font-size: 13px">Format: your-store.myshopify.com</span>
        </div> -->
        <div class="d-flex mt-3">
          <button type="submit" class="btn bgr-primary text-white m-auto py-2 px-3 d-flex align-items-center">Login
            <div v-if="disableLogin" class="loader loader--style3 ps-1">
              <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="22px" height="22px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <path fill="#fff" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
                  <animateTransform 
                  attributeType="xml"
                  attributeName="transform"
                  type="rotate"
                  from="0 25 25"
                  to="360 25 25"
                  dur="0.6s"
                  repeatCount="indefinite"/>
                </path>
              </svg>
            </div>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { inject } from 'vue'
import { ref } from "vue"

export default {
  setup(){
    const disableLogin = ref()
    const toast = inject('$toast');
    const shopifyDomain = ref()
    const password = ref()
    const backendLink = process.env.VUE_APP_BACKEND_LINK
    const login = () => {
      if(shopifyDomain.value){ 
        if(shopifyDomain.value.indexOf('https://') !== -1){
          shopifyDomain.value = shopifyDomain.value.replace('https://', '')
        }
        if(shopifyDomain.value.indexOf('.myshopify.com') === -1){
          shopifyDomain.value = shopifyDomain.value + '.myshopify.com'
        }
        disableLogin.value = true
        location.href = backendLink + `?shop=${shopifyDomain.value}`
      }
      else{
        toast.open({
          message: "Please enter Shopify Domain",
          type: "error",
          duration: 3000,
          dismissible: true
        })
      }
    }
    return{
      disableLogin,
      login,
      shopifyDomain,
      password
    }
  },
}
</script>