const initialState = {
  plan_info: [],
  plan_cost: {
    free_cost: '0',
    pro_cost: '2.99',
    ultimate_cost: '9.99',
  },
  full_feature: false
}
export const plan = {
  namespaced: true,
  state: initialState,
  actions: {
    setPlan({ commit }, plan_info) {
      commit('setPlan', plan_info)
    },
    setFullFeature({ commit }, full_feature) {
      commit('setFullFeature', full_feature)
    },
  },
  mutations: {
    setPlan(state, plan_info) {
      state.plan_info = plan_info
    },
    setFullFeature(state, full_feature) {
      state.full_feature = full_feature
    },
  }
};