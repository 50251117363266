<template>
  <div class=" fw-bold">
    <div class="accordion" id="accordionPanelsStayOpenExample">
      <div class="accordion-item border" v-if="settings.template_number !== 4932">
        <h2 class="accordion-header" id="header-template">
          <button class="accordion-button btn-hover-color fw-bold collapsed text-color-primary" type="button" data-bs-toggle="collapse" data-bs-target="#header" aria-expanded="false" aria-controls="header">
            Header
          </button>
        </h2>
        <div id="header" class="accordion-collapse collapse" aria-labelledby="header-template">
          <div class="accordion-body">
            <HeaderTemplate 
              :setting="settings" 
              :settingOrigin="settingsOrigin"
              :fontFamilyprop="fontFamily"
            />
          </div>
        </div>
      </div>
      <div class="accordion-item border">
        <h2 class="accordion-header" id="body-template">
          <button class="accordion-button btn-hover-color fw-bold collapsed text-color-primary" type="button" data-bs-toggle="collapse" data-bs-target="#body" aria-expanded="false" aria-controls="body">
            Body
          </button>
        </h2>
        <div id="body" class="accordion-collapse collapse" aria-labelledby="body-template">
          <div class="accordion-body">
            <BodyTemplate
              :setting="settings" 
              :fontFamilyprop="fontFamily"
              :fontWeightsprop="fontWeights"
            />
          </div>
        </div>
      </div>
      <div class="accordion-item border" v-if="settings.template_number !== 4932">
        <h2 class="accordion-header" id="footer-template">
          <button class="accordion-button btn-hover-color fw-bold collapsed text-color-primary" type="button" data-bs-toggle="collapse" data-bs-target="#footer" aria-expanded="false" aria-controls="footer">
            Footer text
          </button>
        </h2>
        <div id="footer" class="accordion-collapse collapse" aria-labelledby="footer-template">
          <div class="accordion-body">
            <FooterTemplate
            :setting="settings" 
              :settingOrigin="settingsOrigin"
              :fontFamilyprop="fontFamily"
            />
          </div>
        </div>
      </div>
      <div class="accordion-item border" v-if="settings.template_number !== 4931 && settings.template_number !== 4932">
        <h2 class="accordion-header" id="page-background-template">
          <button class="accordion-button btn-hover-color fw-bold collapsed text-color-primary" type="button" data-bs-toggle="collapse" data-bs-target="#page-background" aria-expanded="false" aria-controls="page-background">
            Page background color
          </button>
        </h2>
        <div id="page-background" class="accordion-collapse collapse" aria-labelledby="page-background-template">
          <div class="accordion-body">
            <PageBackground
              :setting="settings" 
            />
          </div>
        </div>
      </div>
      <div class="accordion-item border" v-if="settings.template_number !== 4931 && settings.template_number !== 4932">
        <h2 class="accordion-header" id="page-width-template">
          <button class="accordion-button btn-hover-color fw-bold collapsed text-color-primary" type="button" data-bs-toggle="collapse" data-bs-target="#page-width" aria-expanded="false" aria-controls="page-width">
            FAQ accordian width
          </button>
        </h2>
        <div id="page-width" class="accordion-collapse collapse" aria-labelledby="page-width-template">
          <div class="accordion-body">
            <AccordianWidth
              :setting="settingsOrigin" 
            />
          </div>
        </div>
      </div>
      <div class="accordion-item border">
        <h2 class="accordion-header" id="back-to-top-btn">
          <button class="accordion-button btn-hover-color fw-bold collapsed text-color-primary" type="button" data-bs-toggle="collapse" data-bs-target="#back-to-top" aria-expanded="false" aria-controls="back-to-top">
            Button Back To Top
          </button>
        </h2>
        <div id="back-to-top" class="accordion-collapse collapse" aria-labelledby="back-to-top-btn">
          <div class="accordion-body">
            <BackToTop
              :setting="settings" 
            />
          </div>
        </div>
      </div>
      <div class="accordion-item border" style="border-bottom: 1px solid #dee2e6 !important" v-if="settings.template_number !== 4931">
        <h2 class="accordion-header" id="water-mark">
          <button class="accordion-button btn-hover-color fw-bold collapsed text-color-primary" type="button" data-bs-toggle="collapse" data-bs-target="#water-mar-btn" aria-expanded="false" aria-controls="water-mar-btn">
            Water Mark
          </button>
        </h2>
        <div id="water-mar-btn" class="accordion-collapse collapse" aria-labelledby="water-mark">
          <div class="accordion-body">
            <WaterMark 
              :settingOrigin="settingsOrigin"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from 'vue'
  import store from '../../../../store';
  import HeaderTemplate from './Header'
  import BodyTemplate from './Body'
  import FooterTemplate from './Footer'
  import BackToTop from './BackToTop'
  import AccordianWidth from './AccordianWidth'
  import WaterMark from './WaterMark'
  import PageBackground from './PageBackground'

  export default {
    props: {
      setting: Array,
      settingOrigin : Array,
      fontWeightsprop: Array,
      fontFamilyprop: Array,
    },
    components:{
      HeaderTemplate: HeaderTemplate,
      BodyTemplate: BodyTemplate,
      FooterTemplate: FooterTemplate,
      BackToTop: BackToTop,
      AccordianWidth: AccordianWidth,
      WaterMark: WaterMark,
      PageBackground: PageBackground
    },
    setup(props, { emit }){
      const settings = ref(props.setting)
      const settingsOrigin = ref(props.settingOrigin)
      const fontFamily = ref(props.fontFamilyprop)
      const fontWeights = ref(props.fontWeightsprop)
      const faqMorePageSetting = ref()
      faqMorePageSetting.value = store.state.data.faq_more_page_setting
      watch(settings.value,settingsOrigin.value, () => {
        emit('data', {settings, settingsOrigin})
      })
      return{
        faqMorePageSetting,
        settings,
        settingsOrigin,
        fontFamily,
        fontWeights
      }
    }
  }
</script>
<style scoped>
  .accordion-button::after,
  .accordion-button::before{
    display: block;
  }
  .accordion-item:first-of-type {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }
  .accordion-item:last-of-type {
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }
  .accordion-item{
    overflow: hidden !important;
    border-bottom: none !important;
  }
  .accordion-button::after{
    width: 20px !important;
    height: 20px !important;
    color: #ff8200 !important;
  }
  .accordion-button{
    line-height: 1.8 !important;
  }
  .accordion-button:not(.collapsed){
    color: #ffffff !important;
    background-color: #ff8200 !important;
  }
  .btn-hover-color:hover{
    background-color: #f6f6f7;
  }
</style>