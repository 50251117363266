<template>
  <div class="">
    <!-- <div class="border-bottom px-4 py-3 fw-bold d-flex align-items-center justify-content-between">
      <span>Header</span>
    </div> -->
    <div class="">
      <div class="d-flex align-items-center justify-content-between mb-3" v-if="settings.template_number !== 4931">
        <label for="banner-visible">
          <span>Show banner</span>
        </label>
        <div class="form-check form-switch" style="margin-right: -10px;">
          <input :disable="!faqMorePageSetting.active_template && settings.template_number !== 1" class="form-check-input me-2 mt-0 fs-5" type="checkbox" id="banner-visible" v-model="settings.banner_visible">
        </div>
      </div>  
      <div class="d-flex flex-column">
        <label for="height"><span>Header height:</span> {{settings.banner_height}}px</label>
        <input class="form-range" type="range" min="0" max="460" step="2" id="height" v-model="settings.banner_height">
      </div>
      <div v-if="settings.template_number === 6">
        <span>Header background color</span>
        <div class="d-flex align-items-center mt-2 border p-3 rounded">
          <div>
            <input v-model="settings.faq_hover_color" type="color" class="p-0 m-1" style="height: 34px; width: 34px; border: none; border-radius: 10px; cursor:pointer">
          </div>
        <input v-model="settings.faq_hover_color" class="form-control ms-2" type="text" placeholder="" style="height: 34px; width: 150px">
      </div>
    </div>
    </div>
    <div class="pt-4">
      <nav class="mb-3">
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button class="nav-link col fw-bold active" id="page_title-tab" data-bs-toggle="tab" data-bs-target="#page_title" type="button" role="tab" aria-controls="page_title" aria-selected="true">Page title</button>
          <button class="nav-link col fw-bold" id="search_input-tab" data-bs-toggle="tab" data-bs-target="#search_input" type="button" role="tab" aria-controls="search_input" aria-selected="false">Search bar</button>
          <button class="nav-link col fw-bold" id="intro_text-tab" data-bs-toggle="tab" data-bs-target="#intro_text" type="button" role="tab" aria-controls="intro_text" aria-selected="false">Intro text</button>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="page_title" role="tabpanel" aria-labelledby="page_title-tab">
          <div>
            <div class="">
              <div class="d-flex justify-content-between align-items-center">
                <p class="m-0">Show page title</p>
                <div class="form-check form-switch" style="margin-right: -10px;">
                  <input :disable="!faqMorePageSetting.active_template && settings.template_number !== 1" v-model="settingsOrigin.show_page_title" class="form-check-input fs-5" type="checkbox" id="flexSwitchCheckDefault" style="cursor:pointer">
                </div>
              </div>
              <div class="d-flex justify-content-between align-items-center my-3">
                <p class="m-0">Use page title in Product page</p>
                <div class="form-check form-switch" style="margin-right: -10px;">
                  <input v-model="settingsOrigin.title_product_faq" class="form-check-input fs-5" type="checkbox" id="flexSwitchCheckDefault" style="cursor:pointer">
                </div>
              </div>
              <div v-if="settingsOrigin.show_page_title">
                <div class="my-3">
                  <label for="page-title">Page title content</label>
                  <input type="text" class="form-control mt-1" @input="changePageTitle" v-model="pageTitleContent">
                </div>
                <div class="mb-3">
                  <span>Font color</span>
                  <div class="d-flex align-items-center mt-2 border p-3 rounded">
                      <div>
                        <input v-model="settings.page_title_color" type="color" class="p-0 m-1" style="height: 34px; width: 34px; border: none; border-radius: 10px; cursor:pointer">
                      </div>
                    <input v-model="settings.page_title_color" class="form-control ms-2" type="text" placeholder="" style="height: 34px; width: 150px">
                  </div>
                </div>
                <div class="mb-3">
                  <label for="fontweight">Font family</label>
                  <select class="form-select mt-1" id="fontweight"  style="cursor:pointer" aria-label="Default select example" v-model="settings.page_title_font">
                    <option v-for="font in fontFamily" :key="font" :value="`${font}`" :style="`font-family: ${font}`">{{ font == 'unset' ? 'Use your store font (not available in live preview)' : font }}</option>
                  </select>
                </div>
                <div class="mb-3">
                  <span>Font size: {{settings.page_title_fontsize}}px</span>
                  <div>
                    <input v-model="settings.page_title_fontsize" min="10" max="60" type="range" class="form-range" id="customRange1">
                  </div>
                </div>
                <div class="mb-3">
                  <span>Padding top: {{ settings.page_title_paddingtop }}px</span>
                  <div>
                    <input v-model="settings.page_title_paddingtop" min="0" max="60"  type="range" class="form-range" id="customRange1">
                  </div>
                </div>
                <div>
                  <span>Padding bottom: {{ settings.page_title_paddingbottom }}px</span>
                  <div>
                    <input v-model="settings.page_title_paddingbottom" min="0" max="60"  type="range" class="form-range" id="customRange1">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="search_input" role="tabpanel" aria-labelledby="search_input-tab">
          <div class="mt-3">
            <div class="">
              <div class="d-flex justify-content-between align-items-center">
                <p class="m-0">Show search bar</p>
                <div class="form-check form-switch" style="margin-right: -10px;">
                  <input v-model="settings.show_search_input" class="form-check-input fs-5" type="checkbox" id="flexSwitchCheckDefault" style="cursor:pointer">
                </div>
              </div>
              <div v-if="settings.show_search_input">
                <div class="my-3">
                  <label for="page-title">Search placeholder</label>
                  <input type="text" class="form-control mt-1" @input="changeSearchPlaceholder" v-model="searchPlaceholder">
                </div>
                <div class="mb-3">
                  <label for="fontweight">Font family</label>
                  <select class="form-select mt-1" id="fontweight"  style="cursor:pointer" aria-label="Default select example" v-model="settings.search_placeholder_font">
                    <option v-for="font in fontFamily" :key="font" :value="`${font}`" :style="`font-family: ${font}`">{{ font == 'unset' ? 'Use your store font (not available in live preview)' : font }}</option>
                  </select>
                </div>
                <div class="mb-3" v-if="settings.template_number !== 4931">
                  <span>Style</span>
                  <div class="d-flex align-items-center justify-content-center mt-2 border p-3 rounded">
                    <div class="rounded py-2 px-3 border bg-white me-4" @click="settings.search_input_style = 1" style="cursor: pointer;" :class="settings.search_input_style == 1? 'bgr-primary text-white' : ''">Style 1</div>
                    <div class="py-2 px-3 border bg-white rounded" @click="settings.search_input_style = 2" :class="settings.search_input_style == 2? 'bgr-primary text-white' : ''" style="cursor: pointer;">Style 2</div>
                  </div>
                </div>
                <div class="mb-3">
                  <span>Placeholder color</span>
                  <div class="d-flex align-items-center mt-2 border p-3 rounded">
                    <div>
                      <input v-model="settings.placeholder_color" type="color" class="p-0 m-1" style="height: 34px; width: 34px; border: none; border-radius: 10px; cursor:pointer">
                    </div>
                    <input v-model="settings.placeholder_color" class="form-control ms-2" type="text" placeholder="" style="height: 34px; width: 150px">
                  </div>
                </div>
                <div class="mb-3" v-if="settings.search_input_style == 2">
                  <span>Micro scope color</span>
                  <div class="d-flex align-items-center mt-2 border p-3 rounded">
                    <div>
                      <input v-model="settings.micro_scope_color" type="color" class="p-0 m-1" style="height: 34px; width: 34px; border: none; border-radius: 10px; cursor:pointer">
                    </div>
                    <input v-model="settings.micro_scope_color" class="form-control ms-2" type="text" placeholder="" style="height: 34px; width: 150px">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="intro_text" role="tabpanel" aria-labelledby="intro_text-tab">
          <div class="mt-3">
            <div class="">
              <div class="d-flex justify-content-between align-items-center">
                <p class="m-0">Show Intro text</p>
                <div class="form-check form-switch" style="margin-right: -10px;">
                  <input v-model="settingsOrigin.show_intro_text" class="form-check-input fs-5" type="checkbox" id="flexSwitchCheckDefault" style="cursor:pointer">
                </div>
              </div>
              <div v-if="settingsOrigin.show_intro_text">
                <div class="my-3">
                  <label for="page-title">Intro text content</label>
                  <input type="text" class="form-control mt-1" @input="changeIntroText" v-model="pageIntroText">
                </div>
                <div class="my-3">
                  <label for="fontweight">Font family</label>
                    <select class="form-select mt-1" id="fontweight"  style="cursor:pointer" aria-label="Default select example" v-model="settings.intro_text_font">
                      <option v-for="font in fontFamily" :key="font" :value="`${font}`" :style="`font-family: ${font}`">{{ font == 'unset' ? 'Use your store font (not available in live preview)' : font }}</option>
                    </select>
                </div>
                <div class="mb-3">
                  <span>Color</span>
                  <div class="d-flex align-items-center mt-2 border p-3 rounded">
                    <div>
                      <input v-model="settings.intro_text_color" type="color" class="p-0 m-1" style="height: 34px; width: 34px; border: none; border-radius: 10px; cursor:pointer">
                    </div>
                    <input v-model="settings.intro_text_color" class="form-control ms-2" type="text" placeholder="" style="height: 34px; width: 150px">
                  </div>
                </div>
                <div class="mb-3">
                  <span>Font size: {{settings.intro_text_fontsize}}px</span>
                  <div>
                    <input v-model="settings.intro_text_fontsize" min="10" max="40"  type="range" class="form-range" id="customRange1">
                  </div>
                </div>
                <div class="mb-3">
                  <span>Padding top: {{ settings.intro_text_paddingtop }}px</span>
                  <div>
                    <input v-model="settings.intro_text_paddingtop" min="0" max="60"  type="range" class="form-range" id="customRange1">
                  </div>
                </div>
                <div>
                  <span>Padding bottom: {{ settings.intro_text_paddingbottom }}px</span>
                  <div>
                    <input v-model="settings.intro_text_paddingbottom" min="0" max="60"  type="range" class="form-range" id="customRange1">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from 'vue'
  import store from '../../../../store';
  export default {
    props: {
      setting: Array,
      settingOrigin : Array,
      fontFamilyprop: Array,
    },
    setup(props, { emit }){
      const settings = ref(props.setting)
      const settingsOrigin = ref(props.settingOrigin)
      const fontFamily = ref(props.fontFamilyprop)
      const faqMorePageSetting = ref()
      faqMorePageSetting.value = store.state.data.faq_more_page_setting
      const pageTitleContent = ref()
      pageTitleContent.value = JSON.parse(settingsOrigin.value.page_title_content).filter(item => {
        return item.locale === 'default'
      })[0].content
      const settingsOriginBU = ref([])
      const changePageTitle = () => {
        JSON.parse(settingsOrigin.value.page_title_content).forEach(item => {
          settingsOriginBU.value = []
          if(item.locale === 'default'){
            item.content = pageTitleContent.value
          }
          settingsOriginBU.value.push(item)
        })
        settingsOrigin.value.page_title_content = JSON.stringify(settingsOriginBU.value)
      }

      const searchPlaceholder = ref()
      searchPlaceholder.value = JSON.parse(settingsOrigin.value.search_placehoder).filter(item => {
        return item.locale === 'default'
      })[0].content
      const changeSearchPlaceholder = () => {
        JSON.parse(settingsOrigin.value.search_placehoder).forEach(item => {
          settingsOriginBU.value = []
          if(item.locale === 'default'){
            item.content = searchPlaceholder.value
          }
          settingsOriginBU.value.push(item)
        })
        settingsOrigin.value.search_placehoder = JSON.stringify(settingsOriginBU.value)
      }

      const pageIntroText = ref()
      pageIntroText.value = JSON.parse(settingsOrigin.value.intro_text_content).filter(item => {
        return item.locale === 'default'
      })[0].content

      const changeIntroText = () => {
        JSON.parse(settingsOrigin.value.intro_text_content).forEach(item => {
          settingsOriginBU.value = []
          if(item.locale === 'default'){
            item.content = pageIntroText.value
          }
          settingsOriginBU.value.push(item)
        })
        settingsOrigin.value.intro_text_content = JSON.stringify(settingsOriginBU.value)
      }
      watch(settings.value,settingsOrigin.value, () => {
        emit('data', {settings, settingsOrigin})
      })
      return{
        searchPlaceholder,
        pageIntroText,
        changeSearchPlaceholder,
        changeIntroText,
        changePageTitle,
        pageTitleContent,
        faqMorePageSetting,
        settings,
        settingsOrigin,
        fontFamily,
      }
    }
  }
</script>
<style>
</style>