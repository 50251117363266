<template>
  <div>
    <div style="height: 100vh; width: 100vw" class="d-flex align-items-center justify-content-center flex-column">
      <div>
        <img src="./../../../public/Logo-App.png" style="height: 50px" class="mb-2">
      </div>
      <div>
        <h2 class="text-color-primary" style="font-weight:800">Professional FAQ</h2>
      </div>
      <div class="loader-login mt-3"></div>
    </div>
  </div>
</template>

<script>
import router from './../../router/index';
import { useRoute } from 'vue-router';
import UserDataService from "./../../services/UserDataService"
import { ref } from '@vue/reactivity'
import CategoryDataService from "./../../services/CategoryDataService";
import store from "./../../store/index";
import SettingDataService from "./../../services/SettingDataService";
import FaqMorePageSettingDataService from "./../../services/FaqMorePageSettingDataService";
import TemplateSettingDataService from "../../services/TemplateSettingDataService";
import PlanDataService from "./../../services/PlanDataService"
import { templates } from "./../../store/modules/templates"
export default {
  setup() {
    const backendLink = process.env.VUE_APP_BACKEND_LINK
    const route = useRoute()
    const accessToken = ref() 
    const refreshToken = ref()
    const isAuthenticated = ref()
    const user = ref([])
    const locales = ref([])
    const locale = ref()
    const categories = ref([])
    const shopDomain = ref()
    const message = ref()
    const shopifyDomain = ref()
    const showLoadding = ref(false)
    const localeDefault = ref('default')
    const waitLogin = ref()
    const allProduct = ref([])
    const faqMorePage = ref([])
    const faqMorePageSetting = ref()
    const faqs_bu = ref([])
    const allFaqs = ref([])
    const categories_bu = ref([])
    const allCategory = ref([])
    const categoriesShow = ref([])
    const setting = ref()
    const retrieveAllDataUser = async function() {
      if(route.query.accessToken) {
        accessToken.value = route.query.accessToken
        refreshToken.value = route.query.refreshToken
        store.dispatch('auth/setToken', { accessToken: route.query.accessToken, refreshToken: route.query.refreshToken})
        await UserDataService.getAllDataUser(localeDefault.value)
        .then(async response => {
          if(response.data.data.user){
            try {
              var faq_name = "[FAQ]" + response.data.data.user.shopify_domain
              window.$crisp.push(["set", "user:email", [response.data.data.user.email]]);
              window.$crisp.push(["set", "user:nickname", [faq_name]])
            } catch(e) {
              console.log(e)
            }
            await retrieveUser(response.data.data.user)
            if(response.data.data.plan && response.data.data.plan.plan){
              store.dispatch('plan/setPlan', response.data.data.plan);
              if(response.data.data.user.plan_extra){
                store.dispatch('plan/setFullFeature', true);
              }
              else{
                if(response.data.data.plan.plan == 'Free' || response.data.data.plan.plan == 'Free_01'){
                  store.dispatch('plan/setFullFeature', false);
                }
                else{
                  store.dispatch('plan/setFullFeature', true);
                }
              }
            }
            else{
              if(response.data.data.user.plan_extra){
                await createUserPlan('Free extra')
                store.dispatch('plan/setFullFeature', true);
              }
              else{
                await createUserPlan('Free')
                store.dispatch('plan/setFullFeature', false)
              }
            }
            // store.dispatch('plan/setFullFeature', true);
            if(response.data.data.setting){
              setting.value = response.data.data.setting
              store.dispatch('data/setSettingsData', response.data.data.setting);
            }
            else{
              await createUserSettings()
            }

            if(response.data.data.category.length > 0){
              store.dispatch('data/setCategoriesData', response.data.data.category)
            }
            else{
              await retrieveCategory();
            }

            if(response.data.data.allCategory.length > 0){
              store.dispatch('data/setAllCategoriesData', response.data.data.allCategory)
            }

            if(response.data.data.faq.length > 0){
              faqs_bu.value = response.data.data.faq
              store.dispatch('data/setFaqsData', response.data.data.faq);
            }
            else{
              store.dispatch('data/setFaqsData', []);
            }

            if(response.data.data.allFaq.length > 0){
              allFaqs.value = response.data.data.allFaq
              store.dispatch('data/setAllFaqsData', response.data.data.allFaq);
              setFaqLocale()
            }
            else{
              store.dispatch('data/setAllFaqsData', []);
            }

            if(response.data.data.product.length > 0){
              allProduct.value = response.data.data.product
              store.dispatch('data/setProductsData', response.data.data.product)
            }
            else{
              store.dispatch('data/setProductsData', [])
            }

            if(response.data.data.faqMorePage.length > 0){
              faqMorePage.value = response.data.data.faqMorePage
              store.dispatch('data/setFaqMorePage', response.data.data.faqMorePage)
            }
            else{
              store.dispatch('data/setFaqMorePage', [])
            }

            if(response.data.data.faqMorePageSetting.length > 0){
              faqMorePageSetting.value = response.data.data.faqMorePageSetting[0]
              if((faqMorePage.value.length !== 0 || allProduct.value.length !== 0) && faqMorePageSetting.value.active_feature !== true){
                faqMorePageSetting.value.active_feature = true
                store.dispatch('data/setFaqMorePageSetting', faqMorePageSetting.value)
                await FaqMorePageSettingDataService.update(faqMorePageSetting.value.id, faqMorePageSetting.value)
                .then(response => {
                  const responseData = ref()
                  responseData.value = response.data
                })
                .catch(e => {
                  console.log(e)
                })
              }
              if(store.state.data.settings.faq_template_number !== 1){
                faqMorePageSetting.value.active_template = true
                store.dispatch('data/setFaqMorePageSetting', faqMorePageSetting.value)
                await FaqMorePageSettingDataService.update(faqMorePageSetting.value.id, faqMorePageSetting.value)
                .then(response => {
                  const responseData = ref()
                  responseData.value = response.data
                })
                .catch(e => {
                  console.log(e)
                })
              }
              store.dispatch('data/setFaqMorePageSetting', faqMorePageSetting.value)
            }
            else{            
              await retrieveFaqMorePageSetting();
            }

            if(response.data.data.rating){
              store.dispatch('data/setRatingData', response.data.data.rating)
            }
            else{
              store.dispatch('data/setRatingData', [])
            }
          }
        })
        .catch(e => {
          console.log(e)
        })
        if(!accessToken.value){
          isAuthenticated.value = 'false'
          store.dispatch('auth/setAuth', false)
          return
        } 
        else {
          isAuthenticated.value = 'true'
          store.dispatch('auth/setAuth', true)
          showLoadding.value = true
          router.push({
            path: "/storeFAQs",
          })
          // if(store.state.data.settings.tutorial_active){
          //   router.push({
          //     path: "/tutorial",
          //   })
          // }
          // else{
          //   router.push({
          //     path: "/storeFAQs",
          //   })
          // }
        }     
      }
      else if(store.state.auth.accessToken){
        // if(store.state.data.settings.tutorial_active){
        //   router.push({
        //     path: "/tutorial",
        //   })
        // }
        // else{
        //   router.push({
        //     path: "/storeFAQs",
        //   })
        // }
        router.push({
          path: "/storeFAQs",
        })
      }
    }

    const createUserPlan = async function(plan) {
      const data = {
        plan: plan,
        expiry_date:'',
        purchase_date:''
      }
      store.dispatch('plan/setPlan', data)
      await PlanDataService.create(data)
      .catch(e => {
        console.log(e)
      })
    }

    const retrieveUser = async function(user_data) {
      waitLogin.value = true
      if(accessToken.value) {
        user.value = user_data;
        store.dispatch('data/setUserData', user.value)
        shopDomain.value = user.value.shopify_domain
        store.dispatch('auth/setShopifyDomain', shopDomain.value)
        store.dispatch('auth/setEmail', user.value.email)
        locales.value = JSON.parse(user.value.shopLocales).shopLocales
        for(let i = 0; i < locales.value.length; i++) {
          if(!locales.value[i].language){
            const language = ref()
            language.value = new Intl.DisplayNames([locales.value[i].locale], { type: 'language' })
            locales.value[i]['language'] = await language.value.of([locales.value[i].locale])
          }
          if(locales.value[i].primary === true){
            locale.value = locales.value[i].locale
            store.dispatch('auth/setLocale', locale.value);
          }
        }
        store.dispatch('auth/setLanguages', JSON.stringify(locales.value));      }
      else {
        return
      }
    };

    const createUserSettings = async function() {
      await SettingDataService.create(templates.setting)
      .then(async response => {
        store.dispatch('data/setSettingsData', response.data)
        await createTemplateSetting(response.data.id)
      })
      .catch(e => {
        const id = ref()
        id.value = e
      });    
    };

    const setFaqLocale = function(){
      const listLocale = ref([])
      const localesInfo = ref([])
      if(faqs_bu.value.length > 0){
        for(let i = 0; i < faqs_bu.value.length; i++){
          listLocale.value = []
          allFaqs.value.forEach(element => {
            if(faqs_bu.value[i].identify === element.identify && faqs_bu.value[i].category_identify === element.category_identify){
              if(!listLocale.value.some(item => { return item === element.locale })){
                listLocale.value.push(element.locale)
              }
            }
          })
          localesInfo.value = []
          // locales.value = JSON.parse(store.state.auth.languages)
          locales.value.forEach(element => {
            if(listLocale.value.some(item => { return element.locale === item })){
              localesInfo.value.push({
                locale: element.locale,
                added: true,
                language: element.language
              })
            }
            else{
              localesInfo.value.push({
                locale: element.locale,
                added: false,
                language: element.language
              })
            }
          })
          faqs_bu.value[i].locale_info = localesInfo.value
        }
      }
      store.dispatch('data/setFaqsData', faqs_bu.value);
    }

    const createTemplateSetting = async function(settingDataId) {
      let data = templates.template_2
      data.setting_id = settingDataId
      await TemplateSettingDataService.create(data)
      .then(response => {
        store.dispatch('data/setTemplateData', response.data);
      })
      .catch(e => {
        console.log(e)
      });
    }

    const login = function() {
      if(shopifyDomain.value === '' || shopifyDomain.value === undefined){
        message.value = 'Please enter shop name !'
        return
      }
      waitLogin.value = true
      showLoadding.value = true
      location.href = backendLink+"?shop="+shopifyDomain.value+".myshopify.com"
    }

    const changeStore = function(){
      message.value = ''
      if(shopifyDomain.value === '' || shopifyDomain.value === undefined){
        message.value = 'Please enter shop name !'
      }
    }

    const retrieveCategory = async () => {
      const data = {
        locale: localeDefault.value,
        identify: 'Uncategorized1',
        title: "Uncategorized",
        description: "FAQs Uncategorized",
        is_visible: true,
        feature_category: false
      }
      categories.value.push(data)
      await CategoryDataService.create(data)
      .then(response => {
        const id = ref()
        id.value = response
        store.dispatch('data/setCategoriesData', categories.value)
      })
      .catch(e => {
        const id = ref()
        id.value = e
      });
    }

    const retrieveFaqMorePageSetting = async function(){
      const data = store.state.data.faq_more_page_setting_create
      await FaqMorePageSettingDataService.create(data)
      .then(async response => {
        const id = ref()
        id.value = response
        store.dispatch('data/setFaqMorePageSetting', data)
      })
      .catch(e => {
        console.log(e)
      })
    }

    const setCategoryLocale = function(){
      categories_bu.value = store.state.data.categories
      allCategory.value = store.state.data.all_categories
      const listLocale = ref([])
      const localesInfo = ref([])
      if(categories_bu.value){
        for(let i = 0; i < categories_bu.value.length; i++){
          listLocale.value = []
          allCategory.value.forEach(element => {
            if(categories_bu.value[i].identify === element.identify){
              if(!listLocale.value.some(item => { return item === element.locale })){
                listLocale.value.push(element.locale)
              }
            }
          })
          const countFaqs = ref()
          countFaqs.value = faqs_bu.value.filter(item => {
            return item.category_identify === categories_bu.value[i].identify
          }).length
          
          localesInfo.value = []
          locales.value.forEach(element => {
            if(listLocale.value.some(item => { return element.locale === item })){
              localesInfo.value.push({
                locale: element.locale,
                added: true,
                language: element.language
              })
            }
            else{
              localesInfo.value.push({
                locale: element.locale,
                added: false,
                language: element.language
              })
            }
          })
          categories_bu.value[i].locale_info = localesInfo.value
          categories_bu.value[i].count_faqs = countFaqs.value
          categories.value = categories_bu.value
        }
        categoriesShow.value = categories_bu.value
        store.dispatch('data/setCategoriesData', categoriesShow.value)
      }
    }

    const template_value = ref([])
    const rereiveTemplateData = async () => {
      await TemplateSettingDataService.get(setting.value.id)
      .then(async response => {
        if(response.data.length == 1){
          template_value.value = response.data          
          store.dispatch('data/setTemplateData', template_value.value)
        }
        else if(response.data.length > 1){
          TemplateSettingDataService.delete(setting.value.id, setting.value.faq_template_number)
        }
        else{
          createTemplateSetting(setting.value.id)
        }
      })
      .catch(e => {
        console.log(e)
      })
    }

    return {
      rereiveTemplateData,
      setCategoryLocale,
      setFaqLocale,
      retrieveCategory,
      createUserSettings,
      waitLogin,
      showLoadding,
      changeStore,
      message,
      shopifyDomain,
      shopDomain,
      login,
      retrieveUser,
      retrieveFaqMorePageSetting,
      retrieveAllDataUser
    } 
  },
  async created() {
    await this.retrieveAllDataUser()
    this.setCategoryLocale()
    await this.rereiveTemplateData()
  },
}
 
</script>

<style>
  .loader-login {
    border: 6px solid #FF8200;
    border-radius: 50%;
    border-top: 6px solid #f6f6f7;
    border-bottom: 6px solid #f6f6f7;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 1.6s linear infinite;
    animation: spin 1.6s linear infinite;
  }

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>