<template>
  <div id="footer-config">
    <div class="bg-white">
      <div class="d-flex justify-content-between align-items-center">
        <p class="m-0">Show Footer text</p>
        <div class="form-check form-switch" style="margin-right: -10px;">
          <input v-model="settingsOrigin.show_footer_text" class="form-check-input fs-5" type="checkbox" id="flexSwitchCheckDefault" style="cursor:pointer">
        </div>
      </div>
      <div class="mt-3" v-if="settingsOrigin.show_footer_text">
        <div class="mb-3">
          <label for="page-title">Footer text content</label>
          <input type="text" class="form-control mt-1" @input="changeFooterText" v-model="pageFooterText">
        </div>
        <div class="mb-3">
          <label for="fontweight">Font family</label>
          <select class="form-select mt-1" id="fontweight"  style="cursor:pointer" aria-label="Default select example" v-model="settings.footer_text_font">
            <option v-for="font in fontFamily" :key="font" :value="`${font}`" :style="`font-family: ${font}`">{{ font == 'unset' ? 'Use your store font (not available in live preview)' : font }}</option>
          </select>
        </div>
        <div class="mb-3">
          <span>Color</span>
          <div class="d-flex align-items-center mt-2 border p-3 rounded">
            <div>
              <input v-model="settings.footer_text_color" type="color" class="p-0 m-1" style="height: 34px; width: 34px; border: none; border-radius: 10px; cursor:pointer">
            </div>
            <input v-model="settings.footer_text_color" class="form-control ms-2" type="text" placeholder="" style="height: 34px; width: 150px">
          </div>
        </div>
        <div class="mb-3">
          <span>Font size: {{settings.footer_text_fontsize}}px</span>
          <div>
            <input v-model="settings.footer_text_fontsize" min="10" max="40"  type="range" class="form-range">
          </div>
        </div>
        <div class="mb-3" v-if="settings.template_number !== 4931">
          <span>Padding top: {{ settings.footer_text_paddingtop }}px</span>
          <div>
            <input v-model="settings.footer_text_paddingtop" min="0" max="60"  type="range" class="form-range">
          </div>
        </div>
        <div v-if="settings.template_number !== 4931">
          <span>Padding bottom: {{ settings.footer_text_paddingbottom }}px</span>
          <div>
            <input v-model="settings.footer_text_paddingbottom" min="0" max="60"  type="range" class="form-range">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from 'vue'
  import store from '../../../../store';
  export default {
    props: {
        setting: Array,
        settingOrigin : Array,
        fontFamilyprop: Array,
      },
      setup(props, { emit }){
        const settings = ref(props.setting)
        const settingsOrigin = ref(props.settingOrigin)
        const fontFamily = ref(props.fontFamilyprop)
        const faqMorePageSetting = ref()
        faqMorePageSetting.value = store.state.data.faq_more_page_setting

        const pageFooterText = ref()
        pageFooterText.value = JSON.parse(settingsOrigin.value.footer_text_content).filter(item => {
          return item.locale === 'default'
        })[0].content
        const settingsOriginBU = ref([])
        const changeFooterText = () => {
          JSON.parse(settingsOrigin.value.footer_text_content).forEach(item => {
            settingsOriginBU.value = []
            if(item.locale === 'default'){
              item.content = pageFooterText.value
            }
            settingsOriginBU.value.push(item)
          })
          settingsOrigin.value.footer_text_content = JSON.stringify(settingsOriginBU.value)
        }
        
        watch(settings.value,settingsOrigin.value, () => {
          emit('data', {settings, settingsOrigin})
        })
        return{
          changeFooterText,
          pageFooterText,
          faqMorePageSetting,
          settings,
          settingsOrigin,
          fontFamily,
        }
      }
  }
</script>
<style>
</style>