<template>
  <div>
  </div>
</template>

<script>
// import { ref } from '@vue/reactivity'
export default {
  setup(){
    // const shopDomain = ref()
    // shopDomain.value = store.state.auth.shopifyDomain
    // return{
    //   shopDomain
    // }
  },
}
</script>
<style>
</style>