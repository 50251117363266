<template>
  <div class="mb-3 w-100" style="position:relative; top: 41px;font-family:inherit">
    <div class="w-100">
      <div class="" style="margin: auto;">
        <div>
          <div class="text-center fs-1 plan-title my-4" style="">Professional FAQs Plans</div>
          <div class="p-4 px-3 px-sm-5 d-flex flex-column justify-content-center flex-lg-row" style="margin: auto;">
            <div class="col-12 col-lg-4 col-xl-3 mb-5 mb-lg-0 me-0 me-lg-4">
              <div class="p-4 px-5 text-center bg-white" style="border: 3px solid #ff8200; border-radius: 10px;">
                <div class="fs-4 mt-3 fw-bold text-color-primary">FREE</div>
                <div class="mt-2 mb-3 d-flex align-items-center justify-content-center">
                  <span class="fs-1 plan-title pe-3">
                    ${{ freeCost }}
                  </span>
                  <span>USD / mo</span>
                </div>
                <div style="max-width: 300px; margin: auto;">
                  <button @click="selectPlan(freePlan, freeCost)" class="btn py-2 btn-primary-hover" style="border-radius: 10px; width: 100%" :style="checkFreePlan == true? 'background-color: #999999' : 'background-color: #ff8200'" :disabled="checkFreePlan" v-if="checkFreePlan">
                    <span class="fs-6 text-white fw-bold">Current Plan</span>
                  </button>
                  <button @click="selectPlan(freePlan, freeCost)" class="btn py-2 btn-primary-hover" style="border-radius: 10px; width: 100%" :style="checkFreePlan == true? 'background-color: #999999' : 'background-color: #ff8200'" v-else>
                    <span class="fs-6 text-white fw-bold">Free</span>
                  </button>
                </div>
                <div class="mt-4 text-start" v-for="item in featureFree" :key="item">
                  <div class="py-1 d-flex">
                    <div>
                      <i class="fa-solid fa-check me-3 text-color-primary"></i>
                    </div>
                    <span>
                      {{ item }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 col-xl-3 mb-5 mb-lg-0 me-0 me-lg-4">
              <div class="p-4 px-5 text-center bg-white" style="border: 3px solid #ff8200; border-radius: 10px;">
                <div class="fs-4 mt-3 fw-bold text-color-primary">PRO</div>
                <div class="mt-2 mb-3 d-flex align-items-center justify-content-center">
                  <span class="fs-1 plan-title pe-3">
                    ${{ proCost }}
                  </span>
                  <span>USD / mo</span>
                </div>
                <div style="max-width: 300px; margin: auto;">
                  <button @click="selectPlan(proPlan, proCost)"  class="btn py-2 btn-primary-hover" style="border-radius: 10px; width: 100%" :style="currentPlan == proPlan? 'background-color: #999999' : 'background-color: #ff8200'" :disabled="(currentPlan == proPlan)" v-if="(currentPlan == proPlan)">
                    <span class="fs-6 text-white fw-bold">Current Plan</span>
                  </button>
                  <button @click="selectPlan(proPlan, proCost)"  class="btn py-2 btn-primary-hover" style="border-radius: 10px; width: 100%" :style="currentPlan == proPlan? 'background-color: #999999' : 'background-color: #ff8200'" v-else>
                    <span class="fs-6 text-white fw-bold">Start 7-day free trial</span>
                  </button>
                </div>
                <div class="mt-4 text-start" v-for="item in featurePro" :key="item">
                  <div class="py-1 d-flex">
                    <div>
                      <i class="fa-solid fa-check me-3 text-color-primary"></i>
                    </div>
                    <span>
                      {{ item }}
                    </span>
                  </div>
                </div>
                <div v-if="currentPlan === proPlan">
                  <button class="btn rounded mt-3 btn-cancel" style="border: 1px solid red" @click="cancelPlan(proPlan)">Cancel</button>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 col-xl-3">
              <div class="p-4 px-5 text-center bg-white" style="border: 3px solid #ff8200; border-radius: 10px;">
                <div class="fs-4 mt-3 fw-bold text-color-primary">ULTIMATE</div>
                <div class="mt-2 mb-3 d-flex align-items-center justify-content-center">
                  <span class="fs-1 plan-title pe-3">
                    ${{ ultimateCost }}
                  </span>
                  <span>USD / mo</span>
                </div>
                <div style="max-width: 300px; margin: auto;">
                  <button @click="selectPlan(ultimatePlan, ultimateCost)"  class="btn py-2 btn-primary-hover" style="border-radius: 10px; width: 100%" :style="currentPlan == ultimatePlan? 'background-color: #999999' : 'background-color: #ff8200'" :disabled="(currentPlan == ultimatePlan)" v-if="(currentPlan == ultimatePlan)">
                    <span class="fs-6 text-white fw-bold">Current Plan</span>
                  </button>
                  <button @click="selectPlan(ultimatePlan, ultimateCost)"  class="btn py-2 btn-primary-hover" style="border-radius: 10px; width: 100%" :style="currentPlan == ultimatePlan? 'background-color: #999999' : 'background-color: #ff8200'" v-else>
                    <span class="fs-6 text-white fw-bold">Start 7-day free trial</span>
                  </button>
                </div>
                <div class="mt-4 text-start" v-for="item in featureUltimate" :key="item">
                  <div class="py-1 d-flex">
                    <div>
                      <i class="fa-solid fa-check me-3 text-color-primary"></i>
                    </div>
                    <span>
                      {{ item }}
                    </span>
                  </div>
                </div>
                <div v-if="currentPlan === ultimatePlan">
                  <button class="btn rounded mt-3 btn-cancel" style="border: 1px solid red" @click="cancelPlan(ultimatePlan)">Cancel</button>
                </div>
              </div>
            </div>              
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
import store from '../../store';
import PlanDataService from './../../services/PlanDataService'
import swal from 'sweetalert';

export default {
  setup(){
    const freeCost = ref(store.state.plan.plan_cost.free_cost)
    const shopifyPlanId = ref(store.state.plan.plan_info.shopify_plan_id)
    const proCost = ref(store.state.plan.plan_cost.pro_cost)
    const ultimateCost = ref(store.state.plan.plan_cost.ultimate_cost)
    const currentPlan = ref(store.state.plan.plan_info.plan)
    const checkFreePlan = ref(true)
    if(currentPlan.value == 'Pro' || currentPlan.value == 'Ultimate'){
      checkFreePlan.value = false
    }
    else{
      checkFreePlan.value = true
    }
    const freePlan = ref('Free')
    const proPlan = ref('Pro')
    const ultimatePlan = ref('Ultimate')
    const freeExtraPlan = ref('Free extra')
    const free01Plan = ref('Free_01')
    const shopDomain = ref(store.state.auth.shopifyDomain)
    const accessToken = ref(store.state.data.user.shopify_access_token)
    const planInfo = ref(store.state.plan.plan_info)
    const featureFree = [
      'Free 15 FAQs',
      'Custom CSS',
      'Import / Export FAQs',
      'Unlimited categories',
      'Unlimited customization',
      'Search bar for customers',
      'Rearrange FAQs / Categories',
      '15 FAQs on product pages (Only Online Store 2.0)',
      'Google SEO snippets'
    ]
    const featurePro = [
      'Everything in FREE plus',
      'Widget',
      '7 days free trial',
      'Unlimited FAQs',
      'Pro template access',
      'Translations support!',
      'Show FAQs on any page',
      'Unlimited FAQs on product pages (Only Online Store 2.0)',
      'Remove WaterMask'
    ]
    const featureUltimate = [
      'Everything in PRO plus',
      'Build a FAQ page on demand',
      'Priority support'
    ]

    const selectPlan = async(plan, cost) => {
      if(plan == freePlan.value && cost == '0'){
        await swal({ title: "Confirm!",
          text: "Are you sure you want to select FREE Plan?",
          dangerMode: false,
          buttons: true,
          type: "success"}).then(async done => {
          if (done) {
            // planInfo.value.plan = freePlan.value
            // currentPlan.value = freePlan.value
            // store.dispatch('plan/setPlan', planInfo.value)
            // store.dispatch('plan/setFullFeature', false)
            const data = {
              plan: plan,
              cost: cost,
              shopify_plan_id: shopifyPlanId.value,
              shop_domain: shopDomain.value,
              token: accessToken.value
            }
            await PlanDataService.chargePlan(data)
            .then(res => {
              window.open(res.data.link)
            })
            location.href = `${process.env.VUE_APP_BACKEND_LINK}/select/plan?plan=${plan}&price=${cost}&shopify_plan_id=${shopifyPlanId.value}&shop=${shopDomain.value}&accessToken=${accessToken.value}&redirect=true`
          }
        });
      }
      else{
        // location.href = `${process.env.VUE_APP_BACKEND_LINK}/select/plan?plan=${plan}&price=${cost}&shopify_plan_id=${shopifyPlanId.value}&shop=${shopDomain.value}&accessToken=${accessToken.value}&redirect=false`
        await swal({ title: "Confirm!",
          text: "Are you sure you want to select PRO Plan?",
          dangerMode: false,
          buttons: true,    
          type: "success"}).then(async done => {
          if (done) {
            const data = {
              plan: plan,
              cost: cost,
              shopify_plan_id: shopifyPlanId.value,
              shop_domain: shopDomain.value,
              token: accessToken.value
            }
            await PlanDataService.chargePlan(data)
            .then(res => {
              window.open(res.data.link)
            })
            // location.href = `${process.env.VUE_APP_BACKEND_LINK}/select/plan?plan=${plan}&price=${cost}&shopify_plan_id=${shopifyPlanId.value}&shop=${shopDomain.value}&accessToken=${accessToken.value}&redirect=true`
          } 
        });
      }
    }

    const cancelPlan = async(plan) => {
      // location.href = `${process.env.VUE_APP_BACKEND_LINK}/select/plan?plan=${plan}&price=${freeCost.value}&shopify_plan_id=${shopifyPlanId.value}&shop=${shopDomain.value}&accessToken=${accessToken.value}&redirect=false`
      await swal({ title: "Confirm!",
        text: "Are you sure you want to cancel this Plan?",
        dangerMode: true,
        buttons: true,
        type: "danger"
      }).then(async done => {
        if (done) {
          planInfo.value.plan = freePlan.value
          currentPlan.value = freePlan.value
          store.dispatch('plan/setFullFeature', false)
          const data = {
            plan: plan,
            cost: freeCost.value,
            shopify_plan_id: shopifyPlanId.value,
            shop_domain: shopDomain.value,
            token: accessToken.value
          }
          await PlanDataService.chargePlan(data)
            .then(res => {
              window.open(res.data.link)
            })
          // location.href = `${process.env.VUE_APP_BACKEND_LINK}/select/plan?plan=${plan}&price=${freeCost.value}&shopify_plan_id=${shopifyPlanId.value}&shop=${shopDomain.value}&accessToken=${accessToken.value}&redirect=true`
          store.dispatch('plan/setPlan', planInfo.value)
        } 
      });
    }

    return{
      featureUltimate,
      checkFreePlan,
      freeExtraPlan,
      free01Plan,
      freePlan,
      proPlan,
      ultimatePlan,
      shopifyPlanId,
      cancelPlan,
      selectPlan,
      proCost,
      ultimateCost,
      featurePro,
      featureFree,
      freeCost,
      currentPlan,
    }
  },
}
</script>

<style>
.plan-title{
  color: #394150; 
  font-weight: 900
}
.btn-cancel{
  color: red
}
.btn-cancel:hover{
  color: #fff;
  background-color: red
}
</style>