<template>
  <div class="bg-white">
    <span>Page background color</span>
    <div class="d-flex align-items-center mt-2 border p-3 rounded">
      <div>
        <input v-model="settings.page_background_color" type="color" class="p-0 m-1" style="height: 34px; width: 34px; border: none; border-radius: 10px; cursor:pointer">
      </div>
      <input v-model="settings.page_background_color" class="form-control ms-2" type="text" placeholder="" style="height: 34px; width: 150px">
    </div>
  </div>
</template>

<script>
  import { ref, watch } from 'vue'
  export default {
    props: {
        setting: Array,
      },
      setup(props, { emit }){
        const settings = ref(props.setting)
        watch(settings.value, () => {
          emit('data', {settings})
        })
        return{
          settings,
        }
      }
  }
</script>
<style>
</style>