import http from "../http-common";
class AdminDataService {
  getAll(offset, limit, plan) {
    return http.get(`/data/admin/${offset}/${limit}/${plan}`);
  }
  searchByShopifyDomain(shopDomain, type) {
    return http.get(`/data/admin/search?${type}=${shopDomain}`);
  }
  filterByPlan(limit, plan){
    return http.get(`/data/filter-admin/${limit}/${plan}`);
  }
}
export default new AdminDataService();