<template>
  <div>
    <div>
      <!-- <div class="d-flex align-items-center text-start position-sticky-menu-embeded py-3 border-bottom">
        <a @click="$router.go(-1)" class="text-decoration-none" style="cursor:pointer">
          <i class="fas fa-long-arrow-alt-left rounded-3 fs-4 d-flex justify-content-center align-items-center me-3" style="border:1px solid #9ea5ac; height: 40px; width: 40px; color: #5c5f62"></i>
        </a>
        <div class="fs-5 fw-bolder text-nowrap">App blocks supported!</div>
      </div> -->
      <div class="mt-4 d-flex align-items-center fs-6 pt-3">
        <div class="bg-white p-4 col-12 col-lg-8 m-auto border" style="border-radius: 10px">
          <div class="border-bottom pb-3 fs-5">
            <p class="fw-bold">Add FAQs on other pages</p>
            <span class="fs-6">Your published theme supports app blocks on the Home, Collection, Cart and Page templates. This makes installation a breeze! Alternatively we provide instructions for legacy themes at the bottom. If you need help, <b style="cursor: pointer; color: #ff8200;" @click="contact">Contact with us</b> or email <b>support@yanet.io</b> for assistance. </span>
          </div>
          <div class="py-3 border-bottom">
            <p class="fs-5">
              <b>Step 1</b>
            </p>
            <span>From the shopify sidebar navigate to <b> Sales Channels > Online Store > Themes</b>. </span>
            <a class="fs-6" style="text-decoration: none;" target="_blank" :href="`https://${shopDomain}/admin/themes`">Go to theme editor <i class="fa-solid fa-arrow-up-right-from-square"></i></a>
            <img src="./../../../public/add-block-shopify/Step-1.png" class="w-100 my-3" alt="">
            <span>Find the theme you wish to add our block to and click Customize.</span>
            <img src="./../../../public/add-block-shopify/Step-1-1.png" class="w-100 my-3" alt="">
          </div>
          <div class="py-3 border-bottom">
            <p class="fs-5">
              <b>Step 2</b>
            </p>
            <span>From the top bar select the template you wish to add our block to. We support <b>Home, Collection, Cart and Page templates</b></span>
            <img src="./../../../public/add-block-shopify/Step-2-MorePage.png" class="w-100 my-3" alt="">
          </div>
          <div class="py-3">
            <p class="fs-5">
              <b>Step 3</b>
            </p>
            <span>Find the section you wish to add our block to and click <b>Add section</b>. In the apps section of the add block popup you will find the <b>Yanet Professional FAQs</b> block. Click to add it and <b>Save</b>.</span>
            <img src="./../../../public/add-block-shopify/Step-3-MorePage.png" class="w-100 mt-3" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import store from "../../store";
  import { ref } from 'vue'
  export default {
    setup() {
      const shopDomain = ref()
      shopDomain.value = store.state.auth.shopifyDomain
      const contact = () => {
        if(document.getElementsByClassName('cc-1hqb')[0]){
          document.getElementsByClassName('cc-1hqb')[0].click()
        }
        else{
          let user_nickname = `[FAQ]${shopDomain.value}`
          window.open(`https://go.crisp.chat/chat/embed/?website_id=35cbcb5a-831c-47fb-9064-0bced009fca9&user_nickname=${user_nickname}`);
        }
      }
      return{
        shopDomain,
        contact,
      }
    }
  }
</script>
<style>

</style>