import http from "../http-common";
class TutorialDataService {
  create(data) {
    return http.post("/tutorial/create", data);
  }
  update() {
    return http.put("/tutorial/update");
  }

}
export default new TutorialDataService();