
import Setting from './../../components/settings/Setting.vue'
import TemplateSetting from './../../components/template-setting/TemplateSetting.vue'
import Template01 from './../../components/template-setting/template/Template01.vue'
import Template02 from './../../components/template-setting/template/Template02.vue'
import Template03 from './../../components/template-setting/template/Template03.vue'
import Template04 from './../../components/template-setting/template/Template04.vue'
import Template05 from './../../components/template-setting/template/Template05.vue'
import Template06 from './../../components/template-setting/template/Template06.vue'
import Template07 from './../../components/template-setting/template/Template07.vue'
import Template08 from './../../components/template-setting/template/Template08.vue'

const designSettingRoutes = [
  {
    path: "/setting",
    name: "setting",
    component: Setting,
    meta: { auth: true },
  },
  {
    path: "/design",
    name: "design",
    component: TemplateSetting,
    meta: { auth: true },
    children:[
      {
        path: "/template-01",
        name: "template-01",
        component: Template01
      },
      {
        path: "/template-02",
        name: "template-02",
        component: Template02
      },
      {
        path: "/template-03",
        name: "template-03",
        component: Template03
      },
      {
        path: "/template-04",
        name: "template-04",
        component: Template04
      },
      {
        path: "/template-05",
        name: "template-05",
        component: Template05
      },
      {
        path: "/template-06",
        name: "template-06",
        component: Template06
      },
      {
        path: "/template-07",
        name: "template-07",
        component: Template07
      },
      {
        path: "/template-08",
        name: "template-08",
        component: Template08
      }
    ]
  },
]

export default designSettingRoutes



// import Setting from './../../components/settings/Setting.vue'
// import TemplateSetting from './../../components/template-setting/TemplateSetting.vue'
// import Template01 from './../../components/template-setting/template/Template01.vue'
// import Template02 from './../../components/template-setting/template/Template02.vue'
// import Template03 from './../../components/template-setting/template/Template03.vue'
// import Template04 from './../../components/template-setting/template/Template04.vue'
// import Template05 from './../../components/template-setting/template/Template05.vue'
// import Template06 from './../../components/template-setting/template/Template06.vue'
// import Template07 from './../../components/template-setting/template/Template07.vue'
// import Template08 from './../../components/template-setting/template/Template08.vue'
// import Header from './../../components/template-setting/template/modules/Header.vue'
// const designSettingRoutes = [
//   {
//     path: "/setting",
//     name: "setting",
//     component: Setting
//   },
//   {
//     path: "/design",
//     name: "design",
//     component: TemplateSetting,
//     children:[
//       {
//         path: "/template-01",
//         name: "template-01",
//         component: Template01,
//         children: [
//           {
//             path: "/template-header",
//             name: "template-header",
//             component: Header,    
//           }
//         ]
//       },
//       {
//         path: "/template-02",
//         name: "template-02",
//         component: Template02,
//         children: [
//           {
//             path: "/template-header",
//             name: "template-header",
//             component: Header,    
//           }
//         ]
//       },
//       {
//         path: "/template-03",
//         name: "template-03",
//         component: Template03,
//         children: [
//           {
//             path: "/template-header",
//             name: "template-header",
//             component: Header,    
//           }
//         ]
//       },
//       {
//         path: "/template-04",
//         name: "template-04",
//         component: Template04,
//         children: [
//           {
//             path: "/template-header",
//             name: "template-header",
//             component: Header,    
//           }
//         ]
//       },
//       {
//         path: "/template-05",
//         name: "template-05",
//         component: Template05,
//         children: [
//           {
//             path: "/template-header",
//             name: "template-header",
//             component: Header,    
//           }
//         ]
//       },
//       {
//         path: "/template-06",
//         name: "template-06",
//         component: Template06,
//         children: [
//           {
//             path: "/template-header",
//             name: "template-header",
//             component: Header,    
//           }
//         ]
//       },
//       {
//         path: "/template-07",
//         name: "template-07",
//         component: Template07,
//         children: [
//           {
//             path: "/template-header",
//             name: "template-header",
//             component: Header,    
//           }
//         ]
//       },
//       {
//         path: "/template-08",
//         name: "template-08",
//         component: Template08,
//         children: [
//           {
//             path: "/template-header",
//             name: "template-header",
//             component: Header,    
//           }
//         ]
//       }
//     ]
//   },
// ]

// export default designSettingRoutes